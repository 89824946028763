import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const useStyles = makeStyles((theme) => ({
  mainText: {
    fontSize: "7rem",
    fontWeight: "600",
    color: theme.palette.primary.textContent,
    marginTop: "5rem",
  },
  secondaryText: {
    fontSize: "3rem",
    fontWeight: "400",
    color: theme.palette.primary.textContent,
    "&::after": {
      content: '""',
      border: `1px solid ${theme.palette.primary.textContent}`,
      animation: `$blink 1000ms infinite`,
    },
  },
  "@global": {
    //need add into global rules
    "@keyframes blink": {
      "0%": {
        opacity: 1,
      },
      "49%": {
        opacity: 1,
      },
      "50%": {
        opacity: 0,
      },
      "100%": {
        opacity: 0,
      },
    },
  },
}));
