/** @format */

import { getApi } from "api/axios";
import { errorToast, successToast } from "components/Toast/Toast";
import { useCallback, useState } from "react";

const getOrderApi = async () => {
    let originalApi = await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL,
    });
    let newApi = { ...originalApi };

    newApi.interceptors.response.use((value) => {
        let newValue = { ...value };
        return newValue;
    });

    return newApi;
};

export const useOrderAPI = (handleNext) => {
    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const postOrder = useCallback(async ({ order, shopId }) => {
        try {
            setSubmitted(true);
            setOrder(null);
            setLoading(true);
            const res = await (
                await getOrderApi()
            ).post(`/shops/${shopId}/orders`, order);
            if (res.data.paymentRedirectURL) {
                localStorage.clear();
                setOrder(res.data);
                setSubmitted(false);
                successToast("Sikeres rendelés leadás!");
                setLoading(false);
                if (res.data.paymentMethod !== "TRANSFER") {
                    window.location.replace(res.data.paymentRedirectURL);
                } else {
                    handleNext();
                }
            } else {
                setSubmitted(false);
                successToast("Sikeres rendelés leadás!");
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            setSubmitted(false);
            errorToast("Sikertelen rendelés leadás, kérjük próbáld újra!");
        }
    }, []);

    return {
        order,
        postOrder,
        loading,
        submitted,
        setSubmitted,
    };
};
