import { useEffect, useState } from 'react';
import { useTheme, Paper, TextField, TextareaAutosize, FormLabel, Button } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import randomShopOwner from 'assets/images/randomphotographer.jpg';
import invertedLogo from 'assets/images/svg/iskolaevkonyv_logo_szines-feliratnelkul.svg';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Formik, Form } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { ContactSchema } from 'validation/Contact/Schemas';

import {
  OuterWrapper,
  UpperWrapper,
  AddressesWrapper,
  PortraitWrapper,
  PortraitImage,
  Name,
  useStyles,
  ContactWrapper,
  CardTitle
} from './styles';
import { useContactAPI } from './hooks/useContactAPI';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { MdAccountCircle, MdEmail, MdPhone } from 'react-icons/md';

const Contact = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { sendEmail } = useContactAPI();

  const [addressData] = useState(
    props?.shopData
      ? [
          {
            name: 'Email cím',
            data: props?.shopData?.owner?.contact?.email ? props?.shopData?.owner?.contact?.email : 'Nincs megadva',
            icon: <MdEmail style={{ color: 'black' }} />
          },
          {
            name: 'Telefonszám',
            data: props?.shopData?.owner?.contact?.phone ? props?.shopData?.owner?.contact?.phone : 'Nincs megadva',
            icon: <MdPhone style={{ color: 'black' }} />
          }
        ]
      : [
          // {
          //   name: "Székhely",
          //   data: `${props?.shopData?.contact?.address?.zipcode}, ${props?.shopData?.contact?.address?.city}, ${props?.shopData?.contact?.address?.streetAndNumber}, `,
          //   icon: <PlaceIcon style={{ color: theme.palette.primary.error }} />,
          // },
          /*{
            name: "Telefonszám",
            data: props?.shopData?.contact?.phone,
            icon: <PhoneIcon style={{ color: 'black' }} />,
          },*/
          {
            name: 'Email cím',
            data: props?.shopData?.contact?.email,
            icon: <MdEmail style={{ color: 'black' }} />
          },
          {
            name: 'Fotós regisztráció',
            data: 'regisztracio@iskolaevkonyv.hu',
            icon: <MdAccountCircle style={{ color: 'black' }} />
          }
        ]
  );

  useEffect(() => {
    document.body.style.overflow = 'visible';
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [message, setMessage] = useState('');

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const captchaRef = useRef();

  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - Kapcsolat</title>
        <meta
          name='description'
          content='Lépj kapcsolatba velünk, ha kérdésed van intézményi fotózás, iskolai fotózás vagy bölcsődei fotózás témában.'
        />
      </Helmet>
      <OuterWrapper>
        <Slide direction='up' in={true} timeout={1000} unmountOnExit>
          <ContactWrapper>
            <CssBaseline />
            <main className={classes.content}>
              {' '}
              <Container className={classes.container}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: name,
                    email: emailAddress,
                    message: message,
                    shopId: shopId > 0 ? shopId : null
                  }}
                  style={{ width: '100%' }}
                  validate={false}
                  validationSchema={ContactSchema}
                  onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
                    setName(values.name);
                    setEmailAddress(values.email);
                    setMessage(values.message);
                    const token = captchaRef.current.getValue();
                    captchaRef.current.reset();
                    if (token) {
                      await sendEmail({
                        values: values,
                        resetForm: setFieldValue,
                        token: token
                      });
                    }
                  }}>
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm }) => (
                    <Form className={classes.form} style={{ position: 'relative' }}>
                      {/* 
                    Hiding this is necessary for now, in multivendor version it is solved already
                    <ContactWrapperBack />
                  <ContactWrapperBack2 /> */}

                      <Grid container spacing={0} justifyContent='center' alignContent='center'>
                        <Grid item xs={12}>
                          <Paper className={classes.contacts}>
                            <UpperWrapper>
                              <Grid item container xs={12}>
                                <Grid item xs={12} md={4}>
                                  <PortraitWrapper>
                                    <PortraitImage
                                      className={classes.image}
                                      style={{
                                        maxWidth: props?.shop && '200px',
                                        maxHeight: props?.shop && '200px'
                                      }}
                                      src={props?.shop ? randomShopOwner : invertedLogo}
                                      alt='Portrait of the photographer'
                                    />
                                  </PortraitWrapper>
                                  <Name>{`${props?.shopData?.owner?.contact?.firstname} ${
                                    props?.shopData?.owner?.contact?.lastname
                                      ? props?.shopData?.owner?.contact?.lastname
                                      : ''
                                  }`}</Name>

                                  {/* Contacts */}

                                  <Table
                                    size='small'
                                    cellPadding='0'
                                    cellSpacing='0'
                                    width='100%'
                                    height={!props?.shop && '350px'}>
                                    <TableBody>
                                      {addressData &&
                                        addressData?.map((address) => (
                                          <>
                                            <TableRow
                                              style={{
                                                borderColor: theme.palette.primary.error
                                              }}
                                              key={address.name}>
                                              <TableCell className={classes.icon} width='10%'>
                                                {address.icon}
                                              </TableCell>
                                              <TableCell className={classes.nametableCell} width='90%'>
                                                {address.name}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow key={address.name + '_1'}>
                                              <TableCell width='10%' className={classes.icon}></TableCell>
                                              <TableCell width='90%' className={classes.tableCell}>
                                                {address.data}
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                  <AddressesWrapper>
                                    <CardTitle>Írj üzenetet</CardTitle>
                                    {/* Forms */}
                                    <Form
                                      onSubmit={handleSubmit}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%'
                                      }}>
                                      <TextField
                                        id='name'
                                        name='name'
                                        autoComplete='name'
                                        value={values.name}
                                        autoFocus
                                        label='Neved'
                                        className={classes.input}
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                      />
                                      <TextField
                                        id='email'
                                        name='email'
                                        autoComplete='email'
                                        value={values.email}
                                        label='Email címed'
                                        className={classes.input}
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                      />
                                      <FormLabel className={classes.label}>Üzeneted</FormLabel>
                                      <TextareaAutosize
                                        id='message'
                                        name='message'
                                        autoComplete='message'
                                        required
                                        value={values.message}
                                        draggable='false'
                                        className={classes.textarea}
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        error={touched.message && Boolean(errors.message)}
                                        helperText={touched.message && errors.message}
                                      />
                                    </Form>

                                    <ReCAPTCHA sitekey={window._env_.REACT_APP_RECAPTCHA_SITE_KEY} ref={captchaRef} />
                                    <Button
                                      className={classes.submitBtn}
                                      type='submit'
                                      // disabled={!verified}
                                      onClick={handleSubmit}>
                                      Küldés
                                    </Button>
                                  </AddressesWrapper>
                                </Grid>
                              </Grid>
                            </UpperWrapper>
                          </Paper>
                        </Grid>
                        {/* {shopId < 1 && (
                        <Grid item xs={12}>
                        <Grid className={classes.content} xs={12}>
                        <iframe
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Datatronic%20kft.+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        width={"100%"}
                        title={"location-map"}
                        height={"100%"}
                        style={{
                          border: 0,
                          height: "22rem",
                          boxShadow:
                          "1px 1px 10px 1px rgba(100,100,100,0.2)",
                        }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                        </Grid>
                        </Grid>
                      )} */}
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Container>
            </main>
          </ContactWrapper>
        </Slide>
      </OuterWrapper>
    </main>
  );
};

export default Contact;
