import { useGroupAPI } from 'pages/Group/hooks/useGroupAPI';
import { setShop } from 'redux/slices/ShopSlice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { errorToast } from 'components/Toast/Toast';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Desc, MainContainer, PortraitImage, PortraitInnerWrapper, Title, useStyles } from './styles';
import { CircularProgress, Paper } from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import logoSvg from 'assets/images/svg/iskolaevkonyv_logo_szines-feliratnelkul.svg';

const EmbeddedLanding = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { fetchGroupBySearchKey } = useGroupAPI();
  const searchKey = useLocation()?.search?.split('=')[1];

  const redirectToGroup = (groupRes) => {
    const path = `gallery/${groupRes.id}`;

    history.push(path, {
      accessKey: searchKey,
      groupId: groupRes.id
    });
  };

  const checkPassword = async () => {
    await fetchGroupBySearchKey({
      searchKey: searchKey
    })
      .then((res) => {
        dispatch(setShop(res?.data?.shopId));
        redirectToGroup(res?.data);
      })
      .catch(() => {
        errorToast(
          'A galéria nem elérhető a megadott keresőkulccsal, ellenőrizd a beírt kulcs helyességét. Ammenyiben mindent rendben találtál, de a galériát továbbra sem sikerül elérni, kérjük vedd fel a kapcsolatot a fotóssal!'
        );
        history.push('/');
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      checkPassword();
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchKey]);

  return (
    <MainContainer>
      <Paper className={classes.paper}>
        <PortraitInnerWrapper>
          <PortraitImage src={logoSvg} alt='Portrait of the photographer' />
        </PortraitInnerWrapper>
        <Title>Üdvözlünk az Iskolaévkönyv oldalán!</Title>
        <Desc>
          Amennyiben mindent rendben találunk, átirányítunk az általad keresett galéria oldalára, köszönjük türelmed!
        </Desc>
        <CircularProgress size={80} style={{ color: 'whitesmoke' }} />
      </Paper>
    </MainContainer>
  );
};

export default EmbeddedLanding;
