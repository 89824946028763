import * as React from "react";
import styles from "./BlogHeader.module.css";
import Divider from "../../General/divider/Divider";
import { MdShare } from "react-icons/md";
import { Skeleton } from "@mui/material";
import { Button } from "@material-ui/core";

const BlogHeader = ({ author, date, loading }) => {
  return loading ? (
    <Skeleton variant="rectangular" width={"100%"} height={"120px"} />
  ) : (
    <>
      <div className={styles.header_container}>
        <p>{author}</p>
        <div className={styles.header_end}>
          <p>{date}</p>
          <Button
            onClick={() => navigator.clipboard.writeText(window.location.href)} // ez csak HTTPS-en működik */}
            startIcon={<MdShare size={20} />}
          />
        </div>
      </div>
      <Divider />
    </>
  );
};

export default BlogHeader;
