import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const Title = styled.h3`
  margin: 0.2rem;
  font-size: 14px;
  font-family: Roboto;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const Hashtag = styled.span`
  color: ${({ theme }) => theme.palette.primary.backgroundSecondary};
`;

export const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: 'column',
    fontFamily: 'Roboto'
  },
}));
