import { getApi } from "api/axios";
import invertedLogo from "assets/images/svg/iskolaevkonyv_logo_szines-feliratnelkul.svg";

import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { setDemo } from "redux/slices/ShopSlice";

export const useShopApi = () => {
  const dispatch = useDispatch();
  const [shopData, setShopData] = useState({
    id: 13,
    owner: {
      contact: {
        firstname: "iskolaévkönyv.hu",
        email: "ugyfelszolgalat@iskolaevkonyv.hu",
      },
    },
    image: invertedLogo,
    contact: {
      firstname: "iskolaévkönyv.hu",
      email: "ugyfelszolgalat@iskolaevkonyv.hu",
      phone: "+36 70 392 7242",
      address: {
        country: "Magyarország",
        zipcode: "2011",
        city: " Budakalász",
        streetAndNumber: "Domb utca 10.",
      },
    },
    socialMedia: {
      facebook: "https://www.facebook.com/datatronic.hu",
      instagram: "https://www.facebook.com/datatronic.hu",
      linkedin: "https://www.linkedin.com/company/datatronic-hu/",
    },
  });

  const getShopById = useCallback(async (shopId) => {
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/shops/${shopId}`);  
      if(res?.data?.isDemo){
        dispatch(setDemo(true))
      } else {
        dispatch(setDemo(false))
      }      
      setShopData(
        res.data        
      );
    } catch (e) {
      setShopData({
        id: 13,
        owner: {
          contact: {
            firstname: "iskolaévkönyv.hu",
            email: "ugyfelszolgalat@iskolaevkonyv.hu",
            phone: "+36 70 392 7242"
          },
        },
        image: invertedLogo,
        contact: {
          firstname: "iskolaévkönyv.hu",
          email: "ugyfelszolgalat@iskolaevkonyv.hu",
          phone: "+36 70 392 7242",
          address: {
            country: "Magyarország",
            zipcode: "2011",
            city: " Budakalász",
            streetAndNumber: "Domb utca 10.",
          },
        },
        socialMedia: {
          facebook: "https://www.facebook.com/datatronic.hu",
          instagram: "https://www.facebook.com/datatronic.hu",
          linkedin: "https://www.linkedin.com/company/datatronic-hu/",
        },
      })
    }
  }, []);

  return { shopData, getShopById };
};
