import * as yup from 'yup'

const SubscriptionSchema = yup.object({
  name: yup.string('Adja meg nevét').required("Szükséges mező"),
  email: yup
    .string('Adja meg email címét')
    .email('Kérem adjon meg egy valós email címet')
    .required("Szükséges mező"),
  });

  export {
    SubscriptionSchema
  };