import { getApi } from "api/axios";
import { errorToast, successToast } from "components/Toast/Toast";
import { useCallback, useState } from "react";

export const useContactAPI = () => {
  const [mailStatus, setMailStatus] = useState(null);

  const sendEmail = useCallback(async ({ values, resetForm, token }) => {
    setMailStatus("loading");
    try {
      await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).post(
        `/shops/mail`,
        { ...values },
        {
          headers: {
            "Content-Type": "application/json",
            "g-recaptcha-response": token,
          },
        }
      );
      successToast("Üzeneted sikeresen továbbítottuk!");
      resetForm("name", "");
      resetForm("email", "");
      resetForm("message", "");
      setMailStatus("sent");
    } catch (e) {
      errorToast(
        "Üzeneted nem sikerült továbbítanunk! A kellemetlenségekért elnézésedet kérjük!"
      );
      setMailStatus("error");
    }
  }, []);

  return {
    mailStatus,
    sendEmail,
  };
};
