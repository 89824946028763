/** @format */

import { Fragment, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { useTheme, Button, TextField } from "@material-ui/core";
import { MdDescription } from "react-icons/md";
import { useFormik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useFooterData } from "./FooterData";

import {
    OuterWrapper,
    FooterWrapper,
    Sections,
    SectionWrapper,
    CopyrightWrapper,
    SectionContent,
    SectionTitle,
    TitleWrapper,
    SectionA,
    SectionLink,
    useStyles,
    PaymentVendorLogoWrapper,
    PaymentVendorLogo,
    StyledLink,
} from "./styles";
import simplepayPng from "assets/images/simplepay_otp_bankcard_hu_top.png";
import barionStrip from "assets/images/barionStrip.svg";
import { useDocumentsApi } from "hooks/useDocumentsApi";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { SubscriptionSchema } from "validation/Subscription/Schemas";
import { successToast, errorToast } from "components/Toast/Toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
} from "@mui/material";

const Footer = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [data, setData] = useState({ name: "", email: "" });
    const shopId = useSelector(
        (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
    );
    
    const history = useHistory();
    const target = useRef(null);
    const baseDocuments = useSelector(
        (state) => state.nonPersistedReducers?.documentSliceReducer?.documents
    );
    const small = useMediaQuery("(max-width:1050px)");

    const { FooterData } = useFooterData(props?.shopData);

    const {
        getDocuments,
        downloadDocuments,
        downloadBaseDocuments,
        documents,
        subscribeNewsletter,
    } = useDocumentsApi();

    const [subSelected, setSubSelected] = useState({
        common: true,
        special: true,
    });

    const handleChange = (e) => {
        setSubSelected({
            ...subSelected,
            [e.target.name]: e.target.checked,
        });
    };

    const { common, special } = subSelected;
    const error = [common, special].filter((v) => v).length < 1;

    useEffect(() => {
        if (shopId && shopId !== -1) {
            getDocuments();
        }
    }, [shopId]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: data.name,
            email: data.email,
        },
        validationSchema: SubscriptionSchema,
        onSubmit: async () => {
            setData(formik.values);
            let values = {
                name: data.name,
                email: data.email,
                id:
                    common && special
                        ? "1,2"
                        : common
                        ? "2"
                        : special
                        ? "1"
                        : "0",
            };
            await subscribeNewsletter({ values }).then((res) => {
                if (res) {
                    successToast("Hírlevélre való feliratkozás sikeres");
                } else {
                    errorToast("Hírlevélre való feliratkozás sikertelen");
                }
            });
        },
    });

    const copyright = () => {
        return (
            <Typography
                align="center"
                style={{
                    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                }}
            >
                {"Copyright © "}
                <RouterLink className={classes.link} to="/">
                    iskolaévkönyv.hu
                </RouterLink>{" "}
                {new Date().getFullYear()}
                {"."}
            </Typography>
        );
    };

    return (
        <OuterWrapper className={classes.outerWrapper} id="hirlevel">
            <FooterWrapper>
                <div
                    style={{
                        backgroundColor: "white",
                        padding: "1rem",
                        marginTop: "2rem",
                        borderRadius: "5px",
                        width: small ? "90%" : "calc(100% - 2rem)",
                    }}
                >
                    <h2 style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                        Feliratkozom a hírlevélre
                    </h2>
                    <div>
                        {" "}
                        Feliratkozás-ra való kattintással egyidejűleg
                        hozzájárulok, hogy a Datatronic Kft a nevemet és e-mail
                        címemet hírlevelezési céllal kezelje és a részemre az
                        iskolaévkönyv.hu rendszerrel és az iskolai fotózással
                        kapcsolatban gazdasági reklámot is tartalmazó email
                        hírleveleket küldjön, ill. kijelentem, hogy az{" "}
                        {
                            <StyledLink
                                ref={target}
                                href="/adatkezelesi_tajekoztato"
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push("/adatkezelesi_tajekoztato");
                                }}
                            >
                                Adatvédelmi tájékoztatót
                            </StyledLink>
                        }{" "}
                        elolvastam, és az abban foglaltakat megismertem.
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        style={{
                            display: "flex",
                            rowGap: "1rem",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                            }}
                        >
                            <TextField
                                style={{ minWidth: "200px", maxWidth: "400px" }}
                                required
                                id="name"
                                name="name"
                                label="Név"
                                fullWidth
                                autoComplete="given-name"
                                value={formik.values.name}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setData({
                                        ...data,
                                        name: e.target.value,
                                    });
                                }}
                                error={
                                    formik.touched.name &&
                                    Boolean(formik.errors.name)
                                }
                                helperText={
                                    formik.touched.name && formik.errors.name
                                }
                            />
                            <TextField
                                style={{ minWidth: "200px", maxWidth: "400px" }}
                                required
                                fullWidth
                                id="email"
                                label="Email cím"
                                name="email"
                                autoComplete="email"
                                value={formik.values.email}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setData({
                                        ...data,
                                        email: e.target.value,
                                    });
                                }}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                                helperText={
                                    formik.touched.email && formik.errors.email
                                }
                            />
                        </div>
                        <p style={{ marginBottom: 0 }}>
                            Ezek a témák érdekelnek:
                        </p>
                        <FormControl
                            required
                            error={error}
                            component="fieldset"
                            // sx={{ m: 3 }}
                            variant="standard"
                        >
                            <FormGroup
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={common}
                                            onChange={handleChange}
                                            name="common"
                                        />
                                    }
                                    label="Általános információk"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={special}
                                            onChange={handleChange}
                                            name="special"
                                        />
                                    }
                                    label="Fotós információk"
                                />
                            </FormGroup>
                            {error && (
                                <FormHelperText>
                                    *Válassz legalább egyet!
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Button
                            style={{ width: "100px" }}
                            disabled={error}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Küldés
                        </Button>
                    </form>
                </div>
                <Sections>
                    {FooterData.sections.map((section, secIdx) => {
                        return (
                            <SectionWrapper key={`footer_section_${secIdx}`}>
                                <TitleWrapper
                                    color={theme.palette.primary.textPrimary}
                                >
                                    <SectionTitle className={classes.title}>
                                        {section?.name}
                                    </SectionTitle>
                                </TitleWrapper>
                                <SectionContent>
                                    {section?.links?.map((link, idx) => {
                                        return (
                                            <Fragment
                                                key={`footer_section_${secIdx}_link_${idx}`}
                                            >
                                                {link?.external ? (
                                                    <>
                                                        <SectionA
                                                            target="_blank"
                                                            href={link?.link}
                                                            rel="noreferrer"
                                                            className={
                                                                classes.link
                                                            }
                                                        >
                                                            {link?.icon}{" "}
                                                            {link?.text}
                                                        </SectionA>
                                                    </>
                                                ) : (
                                                    <>
                                                        <SectionLink
                                                            onClick={
                                                                link?.to === "#"
                                                                    ? () => {}
                                                                    : () =>
                                                                          window.scrollTo(
                                                                              0,
                                                                              0
                                                                          )
                                                            }
                                                            className={
                                                                classes.link
                                                            }
                                                            to={link?.link}
                                                        >
                                                            {link?.icon}{" "}
                                                            {link?.text}
                                                        </SectionLink>
                                                    </>
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                </SectionContent>
                            </SectionWrapper>
                        );
                    })}
                    {FooterData.downloadSections.map((section, downIdx) => {
                        return (
                            <SectionWrapper key={`footer_download_${downIdx}`}>
                                <TitleWrapper
                                    color={theme.palette.primary.textPrimary}
                                >
                                    <SectionTitle className={classes.title}>
                                        {section?.name}
                                    </SectionTitle>
                                </TitleWrapper>
                                <SectionContent>
                                    {section?.name === "Dokumentumok"
                                        ? baseDocuments?.map((el) => {
                                              if (
                                                  el.name.includes("szerződési")
                                              ) {
                                                  return (
                                                      <Fragment
                                                          key={`footer_download_${el.id}_link_${el.id}`}
                                                      >
                                                          <SectionA
                                                              onClick={(
                                                                  event
                                                              ) => {
                                                                  event.preventDefault();
                                                                  downloadBaseDocuments(
                                                                      el.id,
                                                                      el.name
                                                                  );
                                                              }}
                                                              className={
                                                                  classes.link
                                                              }
                                                              key={el.id}
                                                              href="/"
                                                          >
                                                              <MdDescription
                                                                  style={{
                                                                      color: theme
                                                                          .palette
                                                                          .primary
                                                                          .backgroundSecondary,
                                                                  }}
                                                              />{" "}
                                                              {el.name}
                                                          </SectionA>
                                                      </Fragment>
                                                  );
                                              }
                                          })
                                        : ""}
                                    {section?.name === "Adatvédelem"
                                        ? baseDocuments?.map((el) => {
                                              if (
                                                  el.name.includes(
                                                      "tájékoztató"
                                                  )
                                              ) {
                                                  return (
                                                      <Fragment
                                                          key={`footer_download_${el.id}_link_${el.id}`}
                                                      >
                                                          <SectionA
                                                              onClick={(
                                                                  event
                                                              ) => {
                                                                  event.preventDefault();
                                                                  downloadBaseDocuments(
                                                                      el.id,
                                                                      el.name
                                                                  );
                                                              }}
                                                              className={
                                                                  classes.link
                                                              }
                                                              key={el.id}
                                                              href="/"
                                                          >
                                                              <MdDescription
                                                                  style={{
                                                                      color: theme
                                                                          .palette
                                                                          .primary
                                                                          .backgroundSecondary,
                                                                  }}
                                                              />{" "}
                                                              {el.name}
                                                          </SectionA>
                                                      </Fragment>
                                                  );
                                              }
                                          })
                                        : ""}
                                    {section?.links?.map((link, idx) => (
                                        <Fragment
                                            key={`footer_download_${downIdx}_link_${idx}`}
                                        >
                                            {link?.external ? (
                                                <SectionA
                                                    target="_blank"
                                                    href={link?.link}
                                                    rel="noreferrer"
                                                    className={classes.link}
                                                >
                                                    {link?.icon} {link?.text}
                                                </SectionA>
                                            ) : (
                                                <SectionA
                                                    href={link?.href}
                                                    download={link?.download}
                                                    className={classes.link}
                                                    key={idx}
                                                >
                                                    {link?.icon} {link?.text}
                                                </SectionA>
                                            )}
                                        </Fragment>
                                    ))}

                                    {section?.name === "Dokumentumok"
                                        ? documents?.map((el) => {
                                              if (
                                                  el.name.includes(
                                                      "Általános szerződési feltételek"
                                                  ) &&
                                                  shopId
                                              ) {
                                                  return (
                                                      <Fragment
                                                          key={`footer_download_${el.id}_link_${el.id}`}
                                                      >
                                                          <SectionA
                                                              onClick={(
                                                                  event
                                                              ) => {
                                                                  event.preventDefault();
                                                                  downloadDocuments(
                                                                      el.id,
                                                                      el.name
                                                                  );
                                                              }}
                                                              className={
                                                                  classes.shopLink
                                                              }
                                                              key={el.id}
                                                              href="/"
                                                          >
                                                              <MdDescription
                                                                  style={{
                                                                      color: theme
                                                                          .palette
                                                                          .primary
                                                                          .backgroundSecondary,
                                                                  }}
                                                              />{" "}
                                                              {el.name}
                                                          </SectionA>
                                                      </Fragment>
                                                  );
                                              }
                                          })
                                        : ""}
                                    {section?.name === "Adatvédelem"
                                        ? documents?.map((el) => {
                                              if (
                                                  el.name.includes(
                                                      "Adatkezelési tájékoztató"
                                                  ) &&
                                                  shopId
                                              ) {
                                                  return (
                                                      <Fragment
                                                          key={`footer_download_${el.id}_link_${el.id}`}
                                                      >
                                                          <SectionA
                                                              onClick={(
                                                                  event
                                                              ) => {
                                                                  event.preventDefault();
                                                                  downloadDocuments(
                                                                      el.id,
                                                                      el.name
                                                                  );
                                                              }}
                                                              className={
                                                                  classes.link
                                                              }
                                                              key={el.id}
                                                              href="/"
                                                          >
                                                              <MdDescription
                                                                  style={{
                                                                      color: theme
                                                                          .palette
                                                                          .primary
                                                                          .backgroundSecondary,
                                                                  }}
                                                              />{" "}
                                                              {el.name}
                                                          </SectionA>
                                                      </Fragment>
                                                  );
                                              }
                                          })
                                        : ""}
                                </SectionContent>
                            </SectionWrapper>
                        );
                    })}
                </Sections>

                <PaymentVendorLogoWrapper>
                    <a
                        href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        <PaymentVendorLogo
                            src={simplepayPng}
                            alt="simplepay_logo"
                        />
                    </a>
                </PaymentVendorLogoWrapper>
                
                {/* <PaymentVendorLogoWrapper>
                    <a
                        href="https://secure.barion.com/Registration/Individual?lang=hu_HU"
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        <PaymentVendorLogo
                            src={barionStrip}
                            alt="barion_logo"
                        />
                    </a>
                </PaymentVendorLogoWrapper> */}
                <CopyrightWrapper>
                    <SectionContent className={classes.link}>
                        {copyright()}
                    </SectionContent>
                </CopyrightWrapper>
            </FooterWrapper>
        </OuterWrapper>
    );
};

export default Footer;
