import React from "react";
import queries from "../../api/queries/BlogsQueries";
import { useQuery } from "@tanstack/react-query";

export const useBlogsLogic = () => {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 1,
    pageSize: 9,
  });

  const fetchBlogs = useQuery({
    queryKey: ["blogs", paginationModel.page],
    queryFn: () => queries.fetchBlogs({ paginationModel }),
    onSuccess: () => {},
    retry: (failureCount, error) =>
      error?.response?.status === 404 ? false : true,
    refetchOnWindowFocus: false,
  });

  return {
    fetchBlogs,
    setPaginationModel,
    paginationModel,
  };
};
