import { createTheme } from "@material-ui/core/styles";

import siteconfig from "siteconfig.json";

const MainTheme = createTheme({
  palette: {
    primary: {
      main: siteconfig.themes.primary.backgroundPrimary,
      dark: siteconfig.themes.primary.backgroundPrimary,
      success: siteconfig.themes.primary.success,
      error: siteconfig.themes.primary.error,
      info: siteconfig.themes.primary.info,
      primaryGrey: siteconfig.themes.primary.primaryGrey,
      textPrimary: siteconfig.themes.primary.textPrimary,
      textSecondary: siteconfig.themes.primary.textSecondary,
      textContent: siteconfig.themes.primary.textContent,
      backgroundPrimary: siteconfig.themes.primary.backgroundPrimary,
      backgroundSecondary: siteconfig.themes.primary.backgroundSecondary,
      backgroundCover: siteconfig.themes.primary.backgroundCover,
      backgroundContent: siteconfig.themes.primary.backgroundContent,
    },
  },
  typography: {
    main: siteconfig.typography.main,
    secondary: siteconfig.typography.secondary,
    button: {
      fontFamily: siteconfig.typography.secondary,
    },
  },
});

export default MainTheme;
