import settlements from 'constants/settlements.json';
import postalCodes from 'constants/postalCodes.json';
import settlementNames from 'constants/settlementNames.json';
import { useState } from 'react';

export const usePostalCodeFields = () => {
  const [zips, setZips] = useState([]);

  const calcAddress = (key) => {
    return postalCodes[key];
  };

  const calcPostalCode = (key, zip) => {
    const settlementZips = settlements[key];

    if (zip?.length === 4 && Array.isArray(settlementZips) && settlementZips.includes(zip)) {
      return zip;
    }

    if (Array.isArray(settlementZips)) {
      return settlementZips[0];
    }

    return settlementZips;
  };

  const calcAddressSuggestions = (letters) => {
    if (letters.length < 3) return [];
    return settlementNames.filter((name) => name.toLowerCase().startsWith(letters.toLowerCase()));
  };

  const calcZipSuggestions = (city, zip) => {
    if (city.length > 3 && Array.isArray(settlements[city])) {
      let cities = [...settlements[city]];
      setZips(cities);
    } else if (zip.length >= 1) {
      let cities = Object.keys(postalCodes)?.filter((key) => key.toLowerCase().startsWith(zip.toLowerCase()));
      setZips(cities);
    } else {
      setZips([]);
    }
  };

  return {
    calcAddress,
    calcPostalCode,
    calcAddressSuggestions,
    calcZipSuggestions,
    settlements,
    zips
  };
};
