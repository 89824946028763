import React from "react";
import styles from "./MovingIconWidget.module.css";
import { FaLongArrowAltUp } from "react-icons/fa";
import Divider from "components/General/divider/Divider";

//direction = up/down
const MovingIconWidget = React.memo(({ icon, direction, title }) => {
  return (
    <div className={styles.labelled_widget}>
      <div>
        {icon}
        <FaLongArrowAltUp
          fill={"#50C878"}
          style={{
            transform: direction === "down" ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </div>
      <div>
        <h4>{title}</h4>
      </div>
    </div>
  );
});

export default MovingIconWidget;
