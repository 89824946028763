/** @format */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { successToast, errorToast, infoToast } from "components/Toast/Toast";

import { getApi } from "api/axios";

const baseUrl = window._env_.REACT_APP_QDAK_CART_URL + "/carts";

const calcTotal = (items) => {
  var net = 0;
  var gross = 0;
  var vat = 0;
  items.forEach((item) => {
    net += item.unitPrice.netAmount * item.quantity;
    gross += item.unitPrice.grossAmount * item.quantity;
    vat += item.unitPrice.vatAmount * item.quantity;
  });
  return {
    net,
    gross,
    vat,
  };
};

let cartInitialState = {
  couponIds: [],
  items: [],
  totalPrice: {
    netAmount: 0,
    vatAmount: 0,
    taxRate: 0,
    grossAmount: 0,
    currency: "HUF",
  },
  status: "OPEN",
  shopId: 0,
  accessKey: "",
};

const frontendItemToCartItem = (frontendItem) => {
  let newCartItem = {
    modifiers: frontendItem.modifiers,
    productId: frontendItem.id,
    productName: `${frontendItem.name} ${frontendItem.modifiersDesc}`,
    modifierUri: frontendItem.modifierUri,
    imageId: frontendItem.imageId,
    thumbnailURL: frontendItem.src,
    quantity: frontendItem.quantity,
    discount: 0.0, // TODO implementing discount
    unitPrice: frontendItem.unitPrice,
    groupId: frontendItem.groupId,
    groupName: frontendItem.groupName,
    modifiersText: frontendItem.modifiersText,
    accessKey: frontendItem.accessKey,
  };
  return newCartItem;
};

const getTotalPrice = (items) => {
  let { net, gross, vat } = calcTotal(items);

  return {
    netAmount: net,
    grossAmount: gross,
    vatAmount: vat,
    currency: "HUF",
  };
};

const getCartDTO = (items) => ({
  couponIds: [],
  items: items.map((item) => ({
    productId: item.productId,
    productName: item.productName,
    modifierUri: item.modifierUri,
    imageId: item.imageId,
    thumbnailURL: item.thumbnailURL,
    quantity: item.quantity,
    discount: 0.0,
    unitPrice: item.unitPrice,
    groupName: item.groupName,
    groupId: item.groupId,
    modifiersText: item.modifiersText,
    accessKey:
      item.accessKey === undefined
        ? localStorage.getItem(item.groupId)
        : item.accessKey,
  })),
  shopId: localStorage.getItem("shopId"),
  totalPrice: getTotalPrice(items),
  status: "OPEN",
});

export const getCartById = createAsyncThunk(
  "cart/getCartById",
  async ({ id, accessKey }) => {
    const path = baseUrl + `/${id}`;

    const response = await (
      await getApi({ baseUrl: baseUrl })
    ).get(path, { params: { accessKey: accessKey } });
    localStorage.setItem("cartId", id);
    if (id !== null) {
      if (localStorage.getItem(id) === null) {
        localStorage.setItem(id, accessKey);
      }
    }

    return response;
  }
);

export const deleteCart = createAsyncThunk("cart/deleteCart", async (props) => {
  const path = baseUrl + `/${props.id}`;
  const response = await (await getApi({ baseUrl: baseUrl })).delete(path);
  return response;
});
  let cartPutInitialState = cartInitialState;
  delete cartPutInitialState.accessKey;
export const emptyCart = createAsyncThunk("cart/emptyCart", async () => {

  const response = await (
    await getApi({ baseUrl: baseUrl })
  ).put(
    baseUrl,
    { ...cartPutInitialState, id: localStorage.getItem("cartId") },
    {
      params: {
        accessKey: localStorage.getItem(localStorage.getItem("cartId")),
      },
    }
  );
  localStorage.removeItem(localStorage.getItem("cartId"));
  localStorage.removeItem("cartId");
  return response;
});

export const decrementAmountOfItem = createAsyncThunk(
  "cart/decrementAmountOfItem",
  async ({ itemToDecrement }, thunkAPI) => {
    let cart = thunkAPI.getState().nonPersistedReducers.cartSliceReducer.cart;
    let newItems = cart.items.map((item) =>
      parseInt(item.productId) === parseInt(itemToDecrement.productId) &&
      item.modifierUri === itemToDecrement.modifierUri
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );

    if (JSON.parse(localStorage.getItem("cartId")) === null) {
      const response = await (
        await getApi({ baseUrl: baseUrl })
      ).post(baseUrl, getCartDTO(newItems));

      if (response.status >= 200 && response.status < 300) {
        localStorage.setItem("cartId", response.data.id.toString());
      }
      return response;
    } else {
      let cartDTO = getCartDTO(newItems);
      cartDTO.id = parseInt(localStorage.getItem("cartId"));
      const response = await (
        await getApi({ baseUrl: baseUrl })
      ).put(baseUrl, cartDTO, {
        params: {
          accessKey: localStorage.getItem(localStorage.getItem("cartId")),
        },
      });

      return response;
    }
  }
);

export const incrementAmountOfItem = createAsyncThunk(
  "cart/incrementAmountOfItem",
  async ({ itemToIncrement }, thunkAPI) => {
    let cart = thunkAPI.getState().nonPersistedReducers.cartSliceReducer.cart;

    let newItems = cart.items.map((item) =>
      parseInt(item.productId) === parseInt(itemToIncrement.productId) &&
      item.modifierUri === itemToIncrement.modifierUri
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );

    if (JSON.parse(localStorage.getItem("cartId")) === null) {
      const response = await (
        await getApi({ baseUrl: baseUrl })
      ).post(baseUrl, getCartDTO(newItems));

      if (response.status >= 200 && response.status < 300) {
        localStorage.setItem("cartId", response.data.id.toString());
      }
      return response;
    } else {
      let cartDTO = getCartDTO(newItems);
      cartDTO.id = parseInt(localStorage.getItem("cartId"));
      const response = await (
        await getApi({ baseUrl: baseUrl })
      ).put(baseUrl, cartDTO, {
        params: {
          accessKey: localStorage.getItem(localStorage.getItem("cartId")),
        },
      });
      return response;
    }
  }
);

export const addItem = createAsyncThunk(
  "cart/addItem",
  async ({ newItem, setActiveStep }, thunkAPI) => {
    let cart = thunkAPI.getState().nonPersistedReducers.cartSliceReducer.cart;

    let toUpdate = cart.items.find(
      (item) =>
        parseInt(item.productId) === newItem.id &&
        item.modifierUri === newItem.modifierUri
    );
    if (
      newItem.modifierUri.includes("email") &&
      typeof toUpdate != "undefined"
    ) {
      infoToast("Ez a termék már a kosárban van.");

      setActiveStep(4);
      return thunkAPI.rejectWithValue("Item already in cart");
    } else {
      let newArrFiltered = cart.items.filter(
        (item) =>
          !(
            parseInt(item.productId) === newItem.id &&
            item.modifierUri === newItem.modifierUri
          )
      );
      if (typeof toUpdate != "undefined") {
        newArrFiltered.push({
          ...toUpdate,
          quantity: toUpdate.quantity + newItem.quantity,
        });

        infoToast("Ez a termék már a kosárban van, darabszám növelve");
      } else {
        newArrFiltered.push(frontendItemToCartItem(newItem));
      }
      if (
        JSON.parse(localStorage.getItem("cartId")) === null ||
        cart.items.length === 0
      ) {
        const response = await (
          await getApi({ baseUrl: baseUrl })
        ).post(baseUrl, {
          couponIds: [],
          items: newArrFiltered.map((item) => ({
            productId: item.productId,
            productName: item.productName,
            modifierUri: item.modifierUri,
            imageId: item.imageId,
            thumbnailURL: item.thumbnailURL,
            quantity: item.quantity,
            discount: 0.0,
            unitPrice: item.unitPrice,
            groupName: item.groupName,
            groupId: item.groupId,
            modifiersText: item.modifiersText,
            accessKey: localStorage.getItem(newItem.groupId),
          })),
          totalPrice: getTotalPrice(newArrFiltered),
          status: "OPEN",
          shopId: localStorage.getItem("shopId"),
        });
        setActiveStep(4);
        if (response.status >= 200 && response.status < 300) {
          // localStorage.setItem("shopId", newItem.groupId);

          localStorage.setItem("cartId", response.data.id.toString());
          localStorage.setItem(
            response.data.id.toString(),
            localStorage.getItem(newItem.groupId)
          );
        }
        return response;
      } else {
        let cartDTO = getCartDTO(newArrFiltered);
        cartDTO.id = parseInt(localStorage.getItem("cartId"));
        const response = await (
          await getApi({ baseUrl: baseUrl })
        ).put(baseUrl, cartDTO, {
          params: {
            accessKey: localStorage.getItem(localStorage.getItem("cartId")),
          },
        });
        // localStorage.setItem("shopId", newItem.groupId);
        setActiveStep(4);
        return response;
      }
    }
  }
);

export const deleteItem = createAsyncThunk(
  "cart/deleteItem",
  async ({ itemToDelete }, thunkAPI) => {
    let cart = thunkAPI.getState().nonPersistedReducers.cartSliceReducer.cart;

    let newItems = cart.items.filter(
      (item) =>
        !(
          item.modifierUri === itemToDelete.modifierUri &&
          parseInt(itemToDelete.productId) === parseInt(item.productId)
        )
    );

    let cartDTO = getCartDTO(newItems);
    cartDTO.id = parseInt(localStorage.getItem("cartId"));
    const response = await (
      await getApi({ baseUrl: baseUrl })
    ).put(baseUrl, cartDTO, {
      params: {
        accessKey: localStorage.getItem(localStorage.getItem("cartId")),
      },
    });

    return response;
  }
);


export const cartSlice = createSlice({
  name: "cartSlice",
  initialState: {
    cart: cartInitialState,
  },
  extraReducers: {
    [getCartById.fulfilled]: (state, action) => {
      if (action.payload?.data) {
        state.cart = action.payload.data;
      } else {
        localStorage.removeItem("cartId");
      }
    },
    [getCartById.rejected]: () => {
      localStorage.removeItem("cartId");
      errorToast("Kosár lekérdezése sikertelen");
    },
    [deleteCart.fulfilled]: (state, action) => {
      state.cart = action.payload.data;
      successToast("Kosarát töröltük!");
    },
    [deleteCart.rejected]: () => {
      errorToast("Kosár törlése sikertelen");
    },
    [addItem.fulfilled]: (state, action) => {
      successToast("Termék sikeresen kosarához adva");
      state.cart = action.payload.data;
    },
    [addItem.rejected]: () => {
      errorToast("Termék kosárba tétele sikertelen!");
    },
    [deleteItem.fulfilled]: (state, action) => {
      successToast("Termék eltávolítva a kosárból");
      state.cart = action.payload.data;
    },
    [deleteItem.rejected]: () => {
      errorToast("Termék eltávolítása sikertelen!");
    },
    [emptyCart.fulfilled]: (state, action) => {
      successToast("Kosarát kiürítettük");
      state.cart = action.payload.data;
    },
    [emptyCart.rejected]: () => {
      errorToast("Kosár kiürítése sikertelen!");
    },
    [incrementAmountOfItem.fulfilled]: (state, action) => {
      successToast("Termék darabszáma növelve");
      state.cart = action.payload.data;
    },
    [incrementAmountOfItem.rejected]: () => {
      errorToast("Termék darabszámának növelése sikertelen!");
    },
    [decrementAmountOfItem.fulfilled]: (state, action) => {
      successToast("Termék darabszáma csökkentve");
      state.cart = action.payload.data;
    },
    [decrementAmountOfItem.rejected]: () => {
      errorToast("Termék darabszámának csökkentése sikertelen!");
    },
  },
});

export default cartSlice.reducer;
