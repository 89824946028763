import { getApi } from "api/axios";
import { useCallback, useState } from "react";

export const useDeliveryAPI = () => {
  const [deliveryMethods] = useState(null);

  const [itemGroup, setItemGroup] = useState(null);

  const fetchItemGroup = useCallback(async ({ id, shopId }) => {
    try {
      const accessKey = localStorage.getItem(id);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/shops/${shopId}/groups/${id}`, { params: { accessKey } });
      if (res.status >= 200) {
        setItemGroup(res.data);
      }
    } catch (e) {}
  });
  const [schoolGroup, setSchoolGroup] = useState(null);

  const fetchSchoolGroup = useCallback(async ({ id, shopId }) => {
    if (id !== undefined) {
      try {
        const accessKey = localStorage.getItem(id);
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
        ).get(`/shops/${shopId}/groups/${id}`, { params: { accessKey } });
        if (res.status >= 200 && res.status < 300) {
          setSchoolGroup(res.data);
        }
      } catch (e) {}
    }
  });

  const getName = async ({ item, shopId, itemKey }) => {
    try {
      const accessKey = itemKey;
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/shops/${shopId}/groups/${item.groupId}`, {
        params: {
          accessKey,
        },
      });
      if (res.status >= 200 && res.status < 300) {
        if (res.data?.name === "Csoportképek") {
          const res2 = await (
            await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
          ).get(`/shops/${shopId}/groups/${res.data.belongsTo?.id}`, {
            params: {
              accessKey,
            },
          });
          return {
            name: `${res2.data.belongsTo?.name} - ${res.data.belongsTo?.name}`,
            expirationDate: res.data.modifiers[0].validUntil,
          };
        }
        return {
          name: `${res.data.belongsTo?.name} - ${item.groupName}`,
          expirationDate: res.data.modifiers[0].validUntil,
        };
      } else {
        return "";
      }
    } catch (e) {
      return "";
    }
  };

  const getDeliveryTargets = async (
    shopId,
    cart,
    setDeliveryTargets,
    setDeliveryTarget,
    setDeliveryTargetObjects
  ) => {
    const groupNames = [];
    const groupObjects = [];

    cart.items.forEach(async (item) => {
      const currentName = (
        await getName({ item: item, shopId: shopId, itemKey: item.accessKey })
      )?.name;
      if (!groupNames.includes(currentName)) {
        groupNames.push(currentName);
      }
      const currentObject = await getName({
        item: item,
        shopId: shopId,
        itemKey: item.accessKey,
      });
      if (
        groupObjects.find((object) => object.name === currentObject.name) ===
        undefined
      ) {
        groupObjects.push(currentObject);
      }
    });
    setDeliveryTargets(groupNames);
    setDeliveryTarget("Kérjük válasszon...");
    setDeliveryTargetObjects(groupObjects);
  };

  return {
    deliveryMethods,
    getDeliveryTargets,
    fetchItemGroup,
    itemGroup,
    schoolGroup,
    fetchSchoolGroup,
  };
};
