import { useTheme } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

import Link from "@material-ui/core/Link";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UseBreadcrumbData } from "./hooks/UseBreadcrumbData";

import { BreadcrumbWrapper, InnerWrapper, StickyWrapper } from "./styles";

import { useStyles } from "./styles.js";

export const Breadcrumb = ({ password, id, header, sticky, shopId }) => {
  const { fetchParentHeader, parentHeader } = UseBreadcrumbData();

  const theme = useTheme();
  const classes = useStyles(theme);

  const history = useHistory();
  const [parentAccessKey, setParentAccessKey] = useState(null);

  const getParentAccessKey = () => {
    if (header?.belongsTo) {
      setParentAccessKey(localStorage.getItem(header?.id));
    } else {
      setParentAccessKey(null);
    }
  };

  const getParentHeader = () => {
    if (header?.belongsTo?.id !== undefined && parentAccessKey !== undefined) {
      fetchParentHeader({
        shopId: shopId,
        id: header?.belongsTo?.id,
        accessKey: parentAccessKey,
      });
    }
    if (header?.belongsTo?.id !== undefined && parentAccessKey === undefined) {
      fetchParentHeader({
        shopId: shopId,
        id: header?.belongsTo?.id,
      });
    }
  };
  const RouteChange = () => {
    history.push("/");
  };

  useEffect(() => {
    getParentHeader();
  }, [header?.belongsTo?.id, parentAccessKey]);

  useEffect(() => {
    getParentAccessKey();
  }, [header?.id]);

  const handleClick = () => {
    history.push(
      `/gallery/${header?.belongsTo.id}?accessKey=${parentAccessKey}`
    );
  };

  return (
    <BreadcrumbWrapper>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
        <Link
          color="inherit"
          onClick={RouteChange}
          className={classes.rootLink}
        >
          Főoldal
        </Link>
        <InnerWrapper>
          {header?.belongsTo ? (
            <Breadcrumbs
              aria-label="breadcrumb"
              className={classes.innerBreadcrumb}
            >
              {parentHeader?.belongsTo !== undefined && (
                <Typography
                  style={{
                    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                  }}
                >
                  ...
                </Typography>
              )}
              <Typography
                style={{
                  fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                }}
                className={classes.link}
                onClick={handleClick}
              >
                {header?.belongsTo.name}
              </Typography>
              <Typography
                style={{
                  fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                }}
              >
                {header?.name}
              </Typography>
            </Breadcrumbs>
          ) : (
            <Typography
              style={{
                fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
              }}
            >
              {header?.name}
            </Typography>
          )}
        </InnerWrapper>
      </Breadcrumbs>
      <StickyWrapper hidden={!sticky}>
        <Breadcrumbs
          aria-label="breadcrumb"
          sticky={sticky.toString()}
          className={classes.stickyBreadcrumb}
        >
          <Link
            color="inherit"
            onClick={RouteChange}
            className={classes.rootLink}
          >
            Főoldal
          </Link>
          <InnerWrapper>
            {header?.belongsTo ? (
              <Breadcrumbs
                aria-label="breadcrumb"
                className={classes.innerBreadcrumb}
              >
                {parentHeader?.belongsTo !== undefined && (
                  <Typography>...</Typography>
                )}
                <Typography className={classes.link} onClick={handleClick}>
                  {header?.belongsTo.name}
                </Typography>
                <Typography>{header?.name}</Typography>
              </Breadcrumbs>
            ) : (
              <Typography>{header?.name}</Typography>
            )}
          </InnerWrapper>
        </Breadcrumbs>
      </StickyWrapper>
    </BreadcrumbWrapper>
  );
};
