import { makeStyles } from "@material-ui/core";
import sizes from "constants/sizes";
import styled from "styled-components";

export const InnerWrapper = styled.div`
  width: 100%;
`;

export const BreadcrumbWrapper = styled.div`
  padding: 1rem 0;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
  background: ${(props) => props.theme.palette.primary.backgroundPrimary};
  box-shadow: inset 0 6px 15px 0 rgba(0, 0, 0, 0.3);
  outline: none !important;
`;

export const StickyWrapper = styled.div`
  position: fixed;
  top: -80px;
  width: 100%;
  transform: translateY(80px);
  fontfamily: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
  z-index: 1050;
  padding: 1rem 0;
  margin-top: 75px;
  color: ${(props) => props.theme.palette.primary.textPrimary};
  background: ${(props) => props.theme.palette.primary.backgroundPrimary};
  border: 2px solid transparent;
  boxshadow: 0 4px 15px 0 rgb(0 0 0 / 30%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  -webkit-transition: all 0.7s linear;
  @media (min-width: ${sizes.MD}px) {
    margin-top: 0;
  }
`;

export const useStyles = makeStyles((theme) => {
  return {
    stickyBreadcrumb: {
      marginLeft: "1rem",
      color: theme.palette.primary.textPrimary,
    },

    breadcrumb: {
      marginLeft: "1rem",
      color: theme.palette.primary.textPrimary,
    },
    innerBreadcrumb: {
      color: theme.palette.primary.textPrimary,
    },
    stickyLink: {
      cursor: "pointer",
      color: theme.palette.primary.textPrimary,
      textOverflow: "ellipsis",
      maxWidth: "20ch",
      display: "block",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },

    stickyBreadcrumbWrapper: {},
    link: {
      cursor: "pointer",
      color: theme.palette.primary.textPrimary,
      textOverflow: "ellipsis",
      maxWidth: "20ch",
      display: "block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      "&:hover": {
        textDecoration: "underline ",
      },
    },

    rootLink: {
      cursor: "pointer",
      fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    },
  };
});
