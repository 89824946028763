import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { addItem } from "redux/slices/CartSlice";

export const useProductBL = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
    clearAnswers(-1);
  }, [open]);

  const toBoolean = (string) => {
    if (string === "false") return false;
    if (string === "true") return true;
    return null;
  };

  const priorityComparer = (a, b) => {
    if (a.priority < b.priority) {
      return -1;
    }
    if (a.priority > b.priority) {
      return 1;
    }
    return 0;
  };
  const nameComparer = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const separatePrios = (prioSorted) => {
    let curPrio = prioSorted[0].priority;
    let separatedPrios = [];
    let curIdx = 0;
    prioSorted.forEach((curModi) => {
      if (curModi.priority === curPrio) {
        if (separatedPrios[curIdx] == null) {
          separatedPrios[curIdx] = [];
        }
        separatedPrios[curIdx].push(curModi);
      } else {
        // we have reached a modifier which has a higher prio than current
        curPrio = curModi.priority;
        curIdx = curIdx + 1;
        if (separatedPrios[curIdx] == null) {
          separatedPrios[curIdx] = [];
        }
        separatedPrios[curIdx].push(curModi);
      }
    });
    return separatedPrios;
  };
  const sortArraysByName = (separatedPrios) => {
    let separatedPriosOrderedByName = [];
    separatedPrios.forEach((samePrioModis) => {
      let ori = [...samePrioModis];
      separatedPriosOrderedByName.push(ori.sort(nameComparer));
    });
    return separatedPriosOrderedByName;
  };

  const processFinal = (separatedSorted) => {
    let steps = [];
    separatedSorted.forEach((samePrioArr, idx) => {
      let newStep = {
        id: idx,
        name: samePrioArr[0].category,
        answer: null,
        options: samePrioArr,
      };
      steps.push(newStep);
    });
    return steps;
  };

  const addToCart = (group, product, productById) => {
    let productDto = {
      modifiersText: "",
      modifiersDesc: "",
      groupId: group.id,
      imageId: product?.attachments[0]?.fileRef,
      name: product.name,
      unitPrice: {
        netAmount: 0,
        vatAmount: 0,
        grossAmount: 0,
        taxRate: 0,
        currency: "HUF",
      },
      groupName: group.name,
      id: product.id,
      quantity: parseInt(quantity),
      src: product.src,
      modifiers: [],
      modifierUri: "qdak-mod://",
    };

    steps.forEach((step) => {
      if (step.answer === null) {
        return;
      }
      let newModis = [...productDto.modifiers];

      let curNewModi = productById?.modifiers.find(
        (modifier) => modifier.id === step.answer
      );
      newModis.push(curNewModi);

      if (curNewModi.additionalModifiers) {
        curNewModi?.additionalModifiers?.forEach((modifierString) => {
          productDto.modifierUri += modifierString + "/";
        });
      }

      let sumGrossPrice = 0;
      let sumNetPrice = 0;
      let sumVatAmount = 0;

      productDto = { ...productDto, modifiers: newModis };

      productDto.modifiers.forEach((modifier) => {
        sumGrossPrice += modifier.unitPrice.grossAmount;
        sumNetPrice += modifier.unitPrice.netAmount;
        sumVatAmount += sumGrossPrice - sumNetPrice;
      });

      productDto.unitPrice.netAmount = sumNetPrice;
      productDto.unitPrice.grossAmount = sumGrossPrice;
      productDto.unitPrice.vatAmount = sumVatAmount;
      productDto.unitPrice.currency = "HUF";

      let name = "";
      productDto.modifiers.forEach((modifier) => {
        name = name.concat(modifier?.name + " ");
      });

      productDto.modifiersText = name;
      let desc = "";
      productDto.modifiers.forEach((modifier) => {
        desc = modifier?.description;
      });

      productDto.modifiersDesc = desc;
    });

    productDto.modifiers.forEach((modifier) => {
      productDto.modifierUri += modifier.modifierUriSegment + "/";
    });
    dispatch(
      addItem({
        newItem: productDto,
        setActiveStep: setActiveStep,
      })
    );
  };

  const [liked, setLiked] = useState(false);

  const toggleLike = (event, id) => {
    setLiked(!liked);
    localStorage.setItem(id, liked.toString());
    event.stopPropagation();
  };

  const calcPrice = (answer, productById) => {
    let price = productById?.modifiers.find(
      (modifier) => modifier.id === answer
    )?.unitPrice.grossAmount;
    return price;
  };
  const calcTotalPrice = (product, productById) => {
    let total = 0;
    let productDto = {
      id: product.id,
      quantity: parseInt(quantity),
      modifiers: [],
    };
    steps.forEach((step) => {
      if (step.answer === null) {
        return;
      }
      let newModis = [...productDto?.modifiers];
      newModis.push(
        productById?.modifiers.find((modifier) => modifier.id === step.answer)
      );
      productDto = { ...productDto, modifiers: newModis };
    });
    productDto.modifiers?.forEach((modi) => {
      total += modi.unitPrice.grossAmount;
    });
    total *= quantity;

    return total;
  };

  const [quantity, setQuantity] = useState(0);

  const likeState = (id) =>
    localStorage.getItem(id) === null
      ? false
      : toBoolean(localStorage.getItem(id));

  const [steps, setSteps] = useState([]);

  const updateSteps = (currentStep, answerId, deliveryType) => {
    let newSteps = [];

    steps.forEach((step) => {
      if (step !== currentStep) {
        newSteps.push(step);
      } else {
        newSteps.push({ ...step, answer: answerId, deliveryType: deliveryType });
      }
    });
    setSteps(newSteps);
  };

  const [activeStep, setActiveStep] = useState(0);

  const clearAnswers = (fromIdx) => {
    steps.forEach((step, index) => {
      if (fromIdx < index) {
        steps[index] = { ...step, answer: null };
      }
    });
  };

  const handleNext = () => {
    clearAnswers(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    clearAnswers(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const calcSteps = (productById) => {
    let ori = [...productById?.modifiers];
    let prioSorted = ori.sort(priorityComparer); // one array, sorted by prio
    let separatedPrios = separatePrios(prioSorted); // n prios -> n arrays -> n+1 steps (count is the "+1")
    let separatedSorted = sortArraysByName(separatedPrios);
    let newSteps = processFinal(separatedSorted);
    return newSteps;
  };

  return {
    toBoolean,
    priorityComparer,
    nameComparer,
    open,
    toggleOpen,
    addToCart,
    toggleLike,
    calcPrice,
    calcTotalPrice,
    likeState,
    quantity,
    setQuantity,
    steps,
    setSteps,
    activeStep,
    setActiveStep,
    clearAnswers,
    handleNext,
    handleBack,
    liked,
    setLiked,
    updateSteps,
    calcSteps,
  };
};
