import { createSlice } from "@reduxjs/toolkit";

export const DocumentSlice = createSlice({
  name: "baseDocuments",
  initialState: {
    documents: null
  },
  reducers: {
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDocuments } = DocumentSlice.actions;

export default DocumentSlice.reducer;
