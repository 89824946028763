import { getApi } from "api/axios";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { convertBlobToBase64 } from "utils/Converters";
import { errorToast } from "components/Toast/Toast";
import { useDispatch } from "react-redux";
import { setAccessKey } from "redux/slices/AccessKeySlice";

export const useGroupAPI = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(null);
  const [header, setHeader] = useState();
  const [headerLoading, setHeaderLoading] = useState(null);
  const [passwordIsCorrect, setPasswordIsCorrect] = useState(false);

  const fetchHeader = useCallback(
    async ({ shopId, id, accessKey }) => {
      setHeaderLoading(true);
      try {
        setHeader(null);
        setPasswordIsCorrect(false);
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
        ).get(`/shops/${shopId}/groups/${id}`, {
          params: {
            accessKey,
          },
        });
        const correctPassword = res.status === 200;
        if (res.status === 200) {
          setPasswordIsCorrect(correctPassword);
          dispatch(setAccessKey({ id: id, accessKey: accessKey }));
        }
        setHeaderLoading(false);
        setHeader(res.data);
        return correctPassword;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          history.push("/");
          errorToast(
            "A galéria nem elérhető a megadott keresőkulccsal, ellenőrizd a beírt kulcs helyességét. Ammenyiben mindent rendben találtál, de a galériát továbbra sem sikerül elérni, kérjük vedd fel a kapcsolatot a fotóssal!"
          );
        }
        setHeaderLoading(false);
      }
    },
    [history]
  );
  const fetchGroupBySearchKey = useCallback(async ({ searchKey }) => {
    setHeaderLoading(true);
    try {
      setHeader(null);
      setPasswordIsCorrect(false);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/groups/key?searchKey=${searchKey}`);
      setHeaderLoading(false);
      // localStorage.setItem("shopId", res.data.shopId);
      setHeader(res.data);
      return res;
    } catch (e) {}
  }, []);
  const [groups, setGroups] = useState();

  const fetchGroups = useCallback(async ({ shopId, id, accessKey }) => {
    setLoading(true);
    try {
      setGroups(null);
      setPasswordIsCorrect(false);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/shops/${shopId}/groups/${id}/groups`, {
        params: {
          accessKey,
        },
      });
      const correctPassword = res.status === 200;
      if (res.status === 200) {
        setPasswordIsCorrect(correctPassword);
      }
      setLoading(false);
      setGroups(res.data);
      return correctPassword;
    } catch (e) {
      setLoading(false);
    }
  }, []);
  const [products, setProducts] = useState(null);
  const [productsLength, setProductsLength] = useState();

  const fetchProducts = useCallback(
    async ({ shopId, id, accessKey, queryString }) => {
      try {
        setPasswordIsCorrect(false);
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
        ).get(
          `/shops/${shopId}/groups/${id}/products?accessKey=${accessKey}${
            queryString ? queryString : "?"
          }`
        );
        const correctPassword = res.status === 200;

        if (res.status === 200) {
          setPasswordIsCorrect(correctPassword);
        }

        setProducts(
          res.data._embedded?.productDTOList &&
            res.data._embedded?.productDTOList
        );
        setProductsLength(
          res.data.page?.totalElements && res.data.page?.totalElements
        );
        return correctPassword;
      } catch (e) {}
    },
    []
  );

  const [cover, setCover] = useState();

  const fetchCover = useCallback(async ({ shopId, id, accessKey }) => {
    try {
      setCover(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/shops/${shopId}/groups/${id}/cover`, {
        responseType: "blob",
      });
      let image = await convertBlobToBase64(await res.data);
      setCover(image);
    } catch (e) {}
  }, []);

  return {
    fetchHeader,
    fetchGroupBySearchKey,
    header,
    headerLoading,
    fetchGroups,
    groups,
    loading,
    fetchProducts,
    products,
    productsLength,
    fetchCover,
    cover,
    passwordIsCorrect,
  };
};
