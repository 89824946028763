/** @format */

import styled from "styled-components";
import { makeStyles } from "@material-ui/core";

export const OuterWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3rem;
`;
export const DocumentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const useStyles = makeStyles((theme) => ({
    dataHandlingWrapper: {
        backgroundColor: theme.palette.primary.backgroundSecondary,
        boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    },
    title: {
        padding: "0.5rem",
        color: theme.palette.primary.textSecondary,
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "3rem",
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
            fontSize: "4rem",
        },
    },
    subTitle: {
        textAlign: "center",
        fontWeight: "bold",
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
        },
        paddingTop: "2rem",
        color: theme.palette.primary.textPrimary,
    },
    paragraph: {
        margin: "1rem",
        display: "block",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
        },
    },
    list: {
        display: "block",
        margin: "0 0 0 2rem",
    },
}));
