import { Fragment, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import { useStyles } from "./styles";
import { Box, Button } from "@material-ui/core";
import CompanyForm from "./CompanyForm/CompanyForm";
import PersonalForm from "./PersonalForm/PersonalForm";
import { TabContext, TabPanel } from "@material-ui/lab";

import { InputAdornment } from "@material-ui/core";
import { useFormik } from "formik";
import TextField from "@material-ui/core/TextField";
import { BillingSchema } from "validation/Checkout/Schemas";
import { useHistory } from "react-router-dom";

const BillingForm = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const [value] = useState("1");

  /*const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "2") {
      props.setData({ ...props.data, isCompany: true });
      props.setIsCompany(true);
    } else {
      props.setData({ ...props.data, isCompany: false });
      props.setIsCompany(false);
    }
  };
  
  
    useEffect(() => {
      // setValue(props.isCompany ? "2" : "1"); // TODO this is the correct logic but business logic is not clear, only personal orders will be taken
      setValue("1");
    }, []);
  */

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: props.data.firstName,
      lastName: props.data.lastName,
      email: props.data.email,
      emailConfirm: props.data.emailConfirm,
      phone: props.data.phone,
      companyName: props.data.companyName,
      VATNumber: props.data.VATNumber,
      GroupVatNumber: props.data.GroupVatNumber,
      SocialVATNumber: props.data.SocialVATNumber,
      street: props.data.street,
      city: props.data.city,
      zip: props.data.zip,
      country: props.data.country,
      isCompany: props.data.isCompany,
    },
    validationSchema: BillingSchema,
    onSubmit: () => {
      props.setData(formik.values);
      props.handleNext();
    },
  });

  return (
    <Fragment>
      <Typography className={classes.dialogTitle} variant="h6" gutterBottom>
        Számlázási adatok
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="Vezetéknév"
              fullWidth
              autoComplete="family-name"
              value={formik.values.firstName}
              autoFocus
              className={classes.input}
              onChange={(e) => {
                formik.handleChange(e);
                props.setData({
                  ...props.data,
                  firstName: e.target.value,
                });
              }}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Keresztnév"
              fullWidth
              autoComplete="given-name"
              value={formik.values.lastName}
              className={classes.input}
              onChange={(e) => {
                formik.handleChange(e);
                props.setData({
                  ...props.data,
                  lastName: e.target.value,
                });
              }}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email cím"
              name="email"
              autoComplete="email"
              value={formik.values.email}
              className={classes.input}
              onChange={(e) => {
                formik.handleChange(e);
                props.setData({
                  ...props.data,
                  email: e.target.value,
                });
              }}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email cím megerősítése"
              name="email"
              autoComplete="email"
              value={formik.values.emailConfirm}
              className={classes.input}
              onChange={(e) => {
                formik.handleChange(e);
                props.setData({
                  ...props.data,
                  emailConfirm: e.target.value,
                });
              }}
              error={
                formik.touched.emailConfirm &&
                Boolean(formik.errors.emailConfirm)
              }
              helperText={
                formik.touched.emailConfirm && formik.errors.emailConfirm
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Telefonszám"
              name="phone"
              autoComplete="phone"
              className={classes.input}
              value={formik.values.phone}
              onChange={(e) => {
                formik.handleChange(e);
                props.setData({
                  ...props.data,
                  phone: e.target.value,
                });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+36</InputAdornment>
                ),
                placeholder: "301234567",
              }}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid>
            <TabContext value={value}>
              <Box
                sx={{
                  color: theme.palette.primary.dark,
                }}
              >
                {/*
                    <TabList
                      centered={true}
                      onChange={handleChange}
                      indicatorColor="primary"
                      aria-label="company selection tab"
                    >
                      <Tab sx={{ margin: "1rem" }} label="Magánszemély" value="1" />
                      <Tab sx={{ margin: "1rem" }} label="Jogi Személy" value="2" />
                    </TabList>
                  */}
              </Box>
              <TabPanel style={{ width: "100%", padding: "1rem" }} value="1">
                {
                  <PersonalForm
                    data={props.data}
                    setData={props.setData}
                    formik={formik}
                  />
                }
              </TabPanel>
              <TabPanel style={{ width: "100%" }} value="2">
                {
                  <CompanyForm
                    data={props.data}
                    setData={props.setData}
                    formik={formik}
                  />
                }
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>

        <Grid
          item
          container
          className={classes.btnGroup}
          spacing={3}
          justifyContent="center"
        >
          <Grid item container xs={12} md={3} justifyContent="center">
            <Button
              variant="outlined"
              onClick={() => {
                history.push("/cart");
              }}
            >
              Vissza a kosárhoz
            </Button>
          </Grid>
          <Grid item container xs={12} md={3} justifyContent="center">
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => {
                formik.handleSubmit();
              }}
              type="submit"
            >
              Tovább
            </Button>
          </Grid>
        </Grid>
      </form>
      {/* <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="isCompany"
                  id="isCompany"
                  checked={props.isCompany}
                  onChange={(e) => {
                    props.setIsCompany(!props.isCompany);
                  }}
                />
              }
              label={"Cégként rendelek"}
            />
          </Grid>
        </Grid>
      </form> */}
    </Fragment>
  );
};

export default BillingForm;
