import { getApi } from "api/axios";
import { useCallback, useState } from "react";
import { convertBlobToBase64 } from "utils/Converters";

export const useProductAPI = () => {
  const [productById, setProductById] = useState();
  const [passwordIsCorrect, setPasswordIsCorrect] = useState(false);

  const fetchProductById = useCallback(async ({ shopId, id, accessKey }) => {
    try {
      setProductById(null);
      setPasswordIsCorrect(false);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/shops/${shopId}/products/${id}`, {
        params: {
          accessKey,
        },
      });
      const correctPassword = res.status === 200;
      if (res.status === 200) {
        setPasswordIsCorrect(correctPassword);
      }
      setProductById(res.data);
      return correctPassword;
    } catch (e) {}
  }, []);
  const [productImage, setProductImage] = useState(null);

  const fetchProductImage = useCallback(async ({ shopId, id, accessKey }) => {
    try {
      setProductImage(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/shops/${shopId}/products/${id}/thumbnail`, {
        params: { accessKey: accessKey },
        responseType: "blob",
      });
      let image = await convertBlobToBase64(await res.data);
      setProductImage(image);
    } catch (e) {}
  }, []);

  return {
    fetchProductById,
    productById,
    passwordIsCorrect,
    fetchProductImage,
    productImage,
  };
};
