import React from "react";
import { classNames } from "../../../utils/react_utils";
import styles from "./StatusChip.module.css";

//size: 'small' | 'medium' | 'large';
const StatusChip = ({ size, sx = "" }) => {
  const style = classNames(styles.course_status_chip, styles[size], sx);

  return <div className={style}>Új!</div>;
};

export default StatusChip;
