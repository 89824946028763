import { Button, Dialog, useTheme } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Transition,
  Title,
  useStyles,
  MyStepper,
  StepIndicator,
  ButtonsWrapper,
} from "./styles";

const NotificationDialog = ({ notifications }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [open, setOpen] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [visibleNotifications, setVisibleNotifications] = useState();

  useEffect(() => {
    if (notifications?.length > 0) {
      setVisibleNotifications(
        // Currently unnecessary new method needed for checking previously readed notifications
        // notifications?.filter(
        //   (n) => !localStorage.getItem(`n${n?.id}confirmed`)
        // )
        notifications
      );
    }
  }, [notifications]);

  return (
    <>
      {visibleNotifications?.length > 0 ? (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          className={classes.dialog}
          onClick={(e) => e.stopPropagation()}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <>
            <Title>{visibleNotifications[activeStep]?.message}</Title>
          </>
          {visibleNotifications?.length > 1 && (
            <MyStepper>
              {notifications?.map((notification, idx) => {
                return (
                  <StepIndicator
                    key={notification.id}
                    style={{
                      background:
                        activeStep === idx
                          ? theme.palette.primary.backgroundPrimary
                          : "lightgrey",
                    }}
                    onClick={() => setActiveStep(idx)}
                  />
                );
              })}
            </MyStepper>
          )}
          <ButtonsWrapper>
            {visibleNotifications?.length > 1 && (
              <Button
                disabled={activeStep === 0}
                className={classes.backBtn}
                style={{
                  background:
                    activeStep !== 0
                      ? theme.palette.primary.backgroundPrimary
                      : "lightgrey",
                }}
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Vissza
              </Button>
            )}

            <Button
              className={classes.btn}
              onClick={() => {
                !localStorage.getItem(
                  `n${visibleNotifications[activeStep]?.id}confirmed`
                ) &&
                  localStorage.setItem(
                    `n${visibleNotifications[activeStep]?.id}confirmed`,
                    true
                  );
                activeStep === notifications?.length - 1
                  ? setOpen(false)
                  : setActiveStep(activeStep + 1);
              }}
            >
              {activeStep === visibleNotifications?.length - 1
                ? "Megértettem"
                : "Tovább"}
            </Button>
          </ButtonsWrapper>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default NotificationDialog;
