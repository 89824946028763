import styled from "styled-components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  itemName: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
    textAlign: "center",
    fontSize: "1.25rem",
    width: "15ch",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  itemData: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    textAlign: "center",
    fontSize: "0.75rem",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  itemCount: {
    position: "absolute",
    color: theme.palette.primary.textSecondary,
    transform: "translateY(30%) translateX(-50%)",
    fontSize: "1.25rem",
  },
  icon: {
    color: theme.palette.primary.textSecondary,
  },
  deleteBtn: {
    "&:hover, :focus": {
      color: theme.palette.primary.error,
    },
  },
}));

export const CountWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const CounterWrapper = styled.div`
  maxwidth: 10rem;
  display: flex;
  flex-direction: row;
  text-align: center;
`;
