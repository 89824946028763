import { createSlice } from "@reduxjs/toolkit";

export const ShopSlice = createSlice({
  name: "shopId",
  initialState: {
    shopId: localStorage.getItem('shopId') || -1,
    isDemo: false,
  },
  reducers: {
    setShop: (state, action) => {
      state.shopId = action.payload;
    },
    setDemo: (state, action) => {
      state.isDemo = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setShop, setDemo } = ShopSlice.actions;

export default ShopSlice.reducer;
