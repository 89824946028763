import * as React from "react";
import styles from "./BlogSubpage.module.css";
import Center from "../../components/General/center/Center";
import BlogHeader from "../../components/blog/BlogHeader/BlogHeader";
import BlogSidebar from "../../components/blog/BlogSidebar/BlogSidebar";
import { Helmet } from "react-helmet";
import { useBlogSubpageLogic } from "./useBlogSubpageLogic";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";

const BlogSubpage = () => {
  const location = useLocation();

  //const slug = location.pathname.split('/').at(-1);
  const { fetchBlog, setUrlSlug } = useBlogSubpageLogic();

  React.useEffect(() => {
    setUrlSlug(location.pathname.split("/").at(-1));
  }, [location]);

  return (
    <main className={styles.container}>
      <Helmet>
        <title>Iskolaévkönyv - {fetchBlog.data?.data?.title ?? "Blog"}</title>
        <meta
          name="description"
          content={fetchBlog.data?.data?.title ?? "Iskolaévkönyv Blog"}
        />
      </Helmet>
      <Center element="section" sx={styles.custom_center}>
        {fetchBlog.isLoading ? (
          <Skeleton variant="rectangular" width={"100%"} height={"400px"} />
        ) : (
          <div
            className={styles.img_con}
            style={{
              backgroundImage: `url(data:image/png;base64,${fetchBlog.data?.data?.thumbnail})`,
            }}
          ></div>
        )}
        <h1 className={styles.title}>{fetchBlog.data?.data?.title}</h1>
        <BlogHeader
          loading={fetchBlog.isLoading}
          author={fetchBlog.data?.data?.author}
          date={new Date(fetchBlog.data?.data?.createdDate).toDateString()}
        />

        <div className={styles.grid_container}>
          {fetchBlog.isLoading ? (
            <Skeleton variant="rectangular" width={"100%"} height={"225px"} />
          ) : (
            <div
              className={styles.blog_content}
              dangerouslySetInnerHTML={{
                __html: fetchBlog.data?.data?.content,
              }}
            />
          )}
          <BlogSidebar id={fetchBlog.data?.data?.id} />
        </div>
      </Center>
    </main>
  );
};

export default BlogSubpage;
