import { IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useTheme } from "@material-ui/core";

import { useStyles, CountWrapper, CounterWrapper } from "./styles";

const ItemCounter = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <CounterWrapper
      style={{
        display:
          props?.category?.includes('email') ? "none" : "flex",
      }}
    >
      <IconButton
        className={classes.icon}
        onClick={props.decrement}
        aria-label="decrement amount"
      >
        <RemoveIcon />
      </IconButton>
      <CountWrapper>
        <Typography
          className={classes.itemCount}
          variant="body1"
          component="span"
        >
          {props.count}
        </Typography>
      </CountWrapper>
      <IconButton
        className={classes.icon}
        onClick={props.increment}
        aria-label="increment amount"
      >
        <AddIcon />
      </IconButton>
    </CounterWrapper>
  );
};

export default ItemCounter;
