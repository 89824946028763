import styled from "styled-components";

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiFormLabel-root, & .MuiInputBase-root, & .MuiFormHelperText-root": {
      fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    },
    "& .MuiOutlinedInput-root": {
      background: `${theme.palette.primary.textPrimary}`,
    },
    minWidth: "100%",
  },
  inputField: {
    padding: '0 0 0 1rem',
    "& .MuiOutlinedInput-root, & .MuiInputBase-root, & .MuiInputBase-colorPrimary, & .MuiInputBase-fullWidth, & .MuiInputBase-formControl, & .MuiInputBase-adornedEnd, & .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: '20px !important',
      height: '46px'
    },
    }
}));

export const FieldWrapper = styled.div`
  width: wrap-content;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  align-items: center;
  @media (min-width: 768px) {
    align-items: unset;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 70%;
  gap: 1rem;
  align-items: center;
  @media (min-width: 480px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const LoginButton = styled.button`
  background:${({ theme }) => theme.palette.primary.backgroundPrimary};
  color: ${({ theme }) => theme.palette.primary.textPrimary};
  border: none;
  width: 100%;
  height: 45px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-Radius: 20px;
  font-size: 1rem;
  align-self: center;
  transition: background 300ms;
  font-family:${({ theme }) => theme.typography.main}, Sans-serif, Arial;
  @media (min-width: 480px) {
    margin-top: 0.4rem;
  }
  &:hover {
    opacity: 80%;
  },`;
