/**
 * Use this comparison function for react memo, if a prop is a one deep object
 */
export function compMaxOneDeep(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== typeof obj2 || obj1 === null || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const numKeys = keys1.length;

  if (numKeys !== keys2.length) {
    return false;
  }

  for (let i = 0; i < numKeys; i++) {
    const key = keys1[i];

    if (
      !Object.prototype.hasOwnProperty.call(obj2, key) ||
      obj1[key] !== obj2[key]
    ) {
      return false;
    }
  }

  return true;
}

/**
 * Use this comparison function for react memo, if a prop is an object with variable (or any) depth
 */
export function compAnyDeep(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== typeof obj2 || obj1 === null || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const numKeys = keys1.length;

  if (numKeys !== keys2.length) {
    return false;
  }

  for (let i = 0; i < numKeys; i++) {
    const key = keys1[i];

    if (
      !Object.prototype.hasOwnProperty.call(obj2, key) ||
      !areEqual(obj1[key], obj2[key])
    ) {
      return false;
    }
  }

  return true;
}

function areEqual(val1, val2) {
  if (val1 === val2) {
    return true;
  }

  if (typeof val1 !== typeof val2 || val1 === null || val2 === null) {
    return false;
  }

  if (Array.isArray(val1) && Array.isArray(val2)) {
    const len = val1.length;

    if (len !== val2.length) {
      return false;
    }

    for (let i = 0; i < len; i++) {
      if (!areEqual(val1[i], val2[i])) {
        return false;
      }
    }

    return true;
  }

  if (typeof val1 === "object" && typeof val2 === "object") {
    const keys1 = Object.keys(val1);
    const keys2 = Object.keys(val2);

    const numKeys = keys1.length;

    if (numKeys !== keys2.length) {
      return false;
    }

    for (let i = 0; i < numKeys; i++) {
      const key = keys1[i];

      if (
        !Object.prototype.hasOwnProperty.call(val2, key) ||
        !areEqual(val1[key], val2[key])
      ) {
        return false;
      }
    }

    return true;
  }

  return false;
}

/**
 * Join classNames like in the React classnames module.
 */
export function classNames(...args) {
  let classes = [];

  for (let i = 0, len = args.length; i < len; i++) {
    const arg = args[i];

    if (!arg) {
      continue;
    }

    const argType = typeof arg;

    if (argType === "string" || argType === "number") {
      classes.push(arg);
    } else if (Array.isArray(arg)) {
      if (!arg.length) {
        continue;
      }

      const inner = classNames(...arg);
      if (inner) {
        classes.push(inner);
      }
    } else if (argType === "object") {
      for (const key in arg) {
        if (Object.prototype.hasOwnProperty.call(arg, key) && arg[key]) {
          classes.push(key);
        }
      }
    }
  }

  return classes.join(" ");
}
