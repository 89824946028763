import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './styles';
import PostalCodeFields from 'components/PostalCodeFields/PostalCodeFields';

const PersonalForm = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id='country'
            name='country'
            label='Ország'
            fullWidth
            disabled
            placeholder='Magyarország'
            autoComplete='shipping country'
            value={'Magyarország'}
            className={classes.input}
            onChange={(e) => {}}
            error={props.formik.touched.country && Boolean(props.formik.errors.country)}
            helperText={props.formik.touched.country && props.formik.errors.country}
          />
        </Grid>
        <PostalCodeFields data={props.data} setData={props.setData} formik={props.formik} />
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='street'
            name='street'
            label='Utca, házszám'
            disabled={props.data.submitted}
            fullWidth
            autoComplete='shipping address-line1'
            value={props.formik.values.street}
            className={classes.input}
            onChange={(e) => {
              props.formik.handleChange(e);
              props.setData({ ...props.data, street: e.target.value });
            }}
            error={props.formik.touched.address && Boolean(props.formik.errors.address)}
            helperText={props.formik.touched.address && props.formik.errors.address}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default PersonalForm;
