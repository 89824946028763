/** @format */

import Grow from "@material-ui/core/Grow";
import { useEffect, useState } from "react";
import { OuterWrapper } from "./styles";
import { useGetAboutMeAPI } from "./hooks/useGetAboutMeAPI";
import { Document, Page, pdfjs } from "react-pdf";
import { useDocumentsApi } from "hooks/useDocumentsApi";
import { Paper } from "@mui/material";
import useWindowSize from "hooks/UseWindowSize";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import aszf_DT_fotos from "assets/files/aszf_DT_fotos.pdf";

const DataTransfer = (props) => {
    const [numPages, setNumPages] = useState(1);
    const [arrayOfPageNumbers, setArrayOfPageNumbers] = useState([]);
    const [file, setFile] = useState();

    const { width } = useWindowSize();
    const maxWidth = 800;

    const { fetchShortDescription, fetchLongDescription } = useGetAboutMeAPI();
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const location = useLocation();

    const { openBaseDocument, getBaseDocuments, baseDocuments } =
        useDocumentsApi();

    function removeTextLayerOffset() {
        const textLayers = document.querySelectorAll(
            ".react-pdf__Page__textContent"
        );
        textLayers.forEach((layer) => {
            const { style } = layer;
            style.display = "none !important";
        });
        const pageLayer = document.querySelectorAll(
            ".react-pdf__Page.pdf-page"
        );
        pageLayer.forEach((layer) => {
            const { style } = layer;
            style.display = "flex";
            style.flexDirection = "column";
            style.alignItems = "center";
            style.justifyContent = "center";
        });
    }

    useEffect(() => {
        document.body.style.overflow = "visible";
        window.scrollTo(0, 0);
        fetchShortDescription();
        fetchLongDescription();
        pdfjs.GlobalWorkerOptions.workerSrc =
            "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js";
        getBaseDocuments();
    }, []);

    useEffect(() => {
        openBaseDocument(
            baseDocuments?.find((el) => el.name.includes("tájékoztató")).id
        ).then((res) => setFile(res));
    }, [baseDocuments]);

    useEffect(() => {
        setArrayOfPageNumbers(new Array(numPages).fill("page"));
    }, [numPages]);

    return (
        <main>
            <Helmet>
                <title>
                    Iskolaévkönyv -{" "}
                    {location.pathname.includes("adatkezelesi_tajekoztato")
                        ? `Adatvédelmi nyilatkozat`
                        : "Általános szerződési feltételek"}
                </title>
                <meta
                    name="description"
                    content={
                        location.pathname.includes("adatkezelesi_tajekoztato")
                            ? "Itt található az iskolaevkonyv.hu Adatvédelmi nyilatkozata"
                            : "Itt találhatók az iskolaevkonyv.hu Általános szerződési feltételei a fotósok számára"
                    }
                />
            </Helmet>
            <OuterWrapper>
                <Grow in={true} timeout={1500} unmountOnExit>
                    <>
                        {file ? (
                            <Document
                                file={
                                    location.pathname.includes(
                                        "adatkezelesi_tajekoztato"
                                    )
                                        ? file
                                        : aszf_DT_fotos
                                }
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                {arrayOfPageNumbers?.length > 0 &&
                                    arrayOfPageNumbers.map((num, idx) => (
                                        <Paper
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                marginBottom: "1rem",
                                                width: `${
                                                    width * 0.9
                                                }px !important`,
                                            }}
                                        >
                                            <Page
                                                key={`${num}_${idx}`}
                                                pageNumber={idx + 1}
                                                width={
                                                    width
                                                        ? Math.min(
                                                              width * 0.7,
                                                              maxWidth
                                                          )
                                                        : maxWidth
                                                }
                                                onLoadSuccess={
                                                    removeTextLayerOffset
                                                }
                                                style={{ overflow: "hidden" }}
                                            />
                                            <p
                                                style={{
                                                    display:
                                                        location.pathname.includes(
                                                            "adatkezelesi_tajekoztato"
                                                        )
                                                            ? "block"
                                                            : "none",
                                                    textAlign: "center",
                                                    marginBottom: "1rem",
                                                    paddingBottom: "2rem",
                                                }}
                                            >
                                                {idx + 1}/{numPages}
                                            </p>
                                        </Paper>
                                    ))}
                            </Document>
                        ) : (
                            <div></div>
                        )}
                    </>
                </Grow>
            </OuterWrapper>
        </main>
    );
};

export default DataTransfer;
