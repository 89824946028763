import React from "react";
import queries from "../../api/queries/BlogsQueries";
import { useQuery } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";

export const useBlogSubpageLogic = () => {
  const [urlSlug, setUrlSlug] = React.useState("");
  const history = useHistory();
  const fetchBlog = useQuery({
    queryKey: ["blog", urlSlug],
    queryFn: () => queries.fetchBlog(urlSlug),
    onSuccess: () => {},
    onError: () => {
      history.push("/blog?page=1");
    },
    retry: (failureCount, error) =>
      error.response.status === 404 ? false : true,
    refetchOnWindowFocus: false,
  });

  return {
    fetchBlog,
    setUrlSlug,
  };
};
