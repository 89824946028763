import { useTheme, Typography } from "@material-ui/core";

import { Wrapper, useStyles } from "./styles";
import { Helmet } from "react-helmet";

const ErrorPage = (props) => {
  const { errorCode, errorText } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - Hiba történt</title>
        <meta
          name="description"
          content="Valami hiba lépett fel, ha szükséges, vegye fel velünk a kapcsolatot!"
        />
      </Helmet>
      <Wrapper>
        <Typography component="h1" variant="h1" className={classes.mainText}>
          {errorCode}
        </Typography>
        <Typography
          component="h2"
          variant="h2"
          className={classes.secondaryText}
        >
          {errorText}
        </Typography>
      </Wrapper>
    </main>
  );
};

export default ErrorPage;
