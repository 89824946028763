import React from "react";
import styles from "./Center.module.css";

const Center = React.memo(
  ({ element, children, color = "", sx = "", ...props }) => {
    const Tag = element;

    return (
      <Tag
        className={styles.center + " " + sx}
        {...props}
        style={{ backgroundColor: color }}
      >
        <div>{children}</div>
      </Tag>
    );
  }
);

Center.displayName = "Center";

export default Center;
