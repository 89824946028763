import { useCallback, useRef } from 'react';

export const useInfiniteScrollObserver = (isFetchingNextPage, hasNextPage, fetchNextPage) => {
  const observerElem = useRef(null);

  const lastRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observerElem.current) observerElem.current.disconnect();

      observerElem.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerElem.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return { lastRef };
};
