import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1),
    width: "95%",
    color: theme.palette.primary.textSecondary,
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  selectEmpty: {
    margin: theme.spacing(2),
  },
}));
