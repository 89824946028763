import HomeIcon from '@material-ui/icons/Home';
import ContactIcon from '@material-ui/icons/ContactPhone';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AboutmeIcon from '@material-ui/icons/Apartment';
import iconSvg from 'assets/images/svg/iskolaevkonyv_logo_inverz-v2_feliratnelkul.svg';

export const useheaderData = (shop) => {
  const HeaderData = {
    icon: iconSvg,
    siteName: 'iskolaévkönyv.hu',
    options: [
      {
        name: 'Főoldal',
        to: '/',
        icon: <HomeIcon />,
        onClick: (dispatch, setActive, idx) => {
          dispatch(setActive(idx));
        },
        divider: false,
        disabled: false
      },
      {
        name: 'Blog',
        to: '/blog?page=1',
        icon: <MenuBookIcon />,
        onClick: (dispatch, setActive, idx) => {
          dispatch(setActive(idx));
        },
        divider: false,
        disabled: false
      },
      {
        name: 'Kapcsolat',
        to: '/contact',
        icon: <ContactIcon />,
        onClick: (dispatch, setActive, idx) => {
          dispatch(setActive(idx));
        },
        divider: false,
        disabled: false
      },
      {
        name: 'Fotós bejelentkezés',
        to: window._env_.REACT_APP_QDAK_REGISTRATION_URL?.split('.hu/')[0] + '.hu',
        icon: <CameraAltIcon />,
        onClick: (dispatch, setActive, idx) => {
          dispatch(setActive(idx));
        },
        divider: false,
        disabled: false,
        external: true
      }
    ]
  };
  return { HeaderData };
};
