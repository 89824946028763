import { useState, useEffect } from "react";

import background from "assets/images/test.jpg";
import Dialog from "@material-ui/core/Dialog";
import { useTheme } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementAmountOfItem,
  incrementAmountOfItem,
  deleteItem,
  emptyCart,
} from "redux/slices/CartSlice";
import { setShop } from "redux/slices/ShopSlice";
import { useHistory, useParams, useLocation } from "react-router-dom";
import CartItem from "./CartItem/CartItem";

import {
  useStyles,
  OuterCartWrapper,
  CartWrapper,
  ItemsWrapper,
  GroupWrapper,
  HeaderAndItems,
  ButtonsWrapper,
  StyledHeader,
  GroupList,
  ItemList,
  SummaryWrapper,
  SummaryItem,
  SummaryData,
  TotalWrapper,
  TotalTitleWrapper,
  TotalPriceWrapper,
} from "./styles";
import { Helmet } from "react-helmet";

const Cart = ({ setAccessKey, setCartId, actions }) => {
  const history = useHistory();

  const theme = useTheme();
  const classes = useStyles(theme);

  const items = useSelector(
    (state) => state.nonPersistedReducers.cartSliceReducer.cart.items
  );
  const cart = useSelector(
    (state) => state.nonPersistedReducers.cartSliceReducer.cart
  );

  const cartId = useParams()?.cartId;

  const accessKey = useLocation().search.split("=")[1];

  const [groups, setGroups] = useState({});

  const itemComparator = (a, b) => {
    if (a.modifiersText < b.modifiersText) {
      return -1;
    }
    if (a.modifiersText > b.modifiersText) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (accessKey && setAccessKey) {
      setAccessKey(accessKey);
    }
  }, [accessKey]);

  useEffect(() => {
    if (cartId && cartId) {
      setCartId(cartId);
    }
  }, [cartId]);

  useEffect(() => {
    let newGroups = {};
    if (items && items.length > 0) {
      items.forEach((item) => {
        if (!newGroups[item.groupName]) {
          newGroups[item.groupName] = [];
        }
        newGroups[item.groupName].push(item);
      });
    }
    // Order
    let orderedGroups = {};
    Object.keys(newGroups)
      .sort()
      .forEach((key) => (orderedGroups[key] = []));
    Object.keys(newGroups).forEach((key) => {
      newGroups[key].sort(itemComparator).forEach((item) => {
        orderedGroups[key].push(item);
      });
    });
    setGroups(orderedGroups);
  }, [items]);

  const dispatch = useDispatch();
  /*const deliveries = [
    // TODO using usestate and GET-ing data from BE
    { id: 1, name: "GLS Futárszolgálat", price: 0 },
    { id: 2, name: "FoxPost automata", price: 0 },
    { id: 3, name: "DHL Futárszolgálat", price: 0 },
    { id: 4, name: "MPL Csomagpont", price: 0 },
  ];
  const [actualDelivery, setActualDelivery] = useState(deliveries[0]);*/

  const countTotalQuantity = () => {
    var total = 0;
    items.forEach((item) => (total += item.quantity));
    return total;
  };

  var totalQuantity = countTotalQuantity();
  const [alert, setAlert] = useState(false);
  const [actualItem, setActualItem] = useState(null);
  

  const toggleAlert = (item) => {
    if (alert) {
      dispatch(deleteItem({ itemToDelete: item }));
      setAlert(false);
    } else {
      setAlert(true);
      setActualItem(item);
    }
  };
  const incQuantity = (item) => {
    dispatch(incrementAmountOfItem({ itemToIncrement: item }));
  };

  const handleEmptyCart = () => {
    dispatch(emptyCart());
  };

  const handleBackToStore = () => {
    let path = "";
    if (localStorage.getItem("shopGalleryId")) {
      path = `/gallery/${localStorage.getItem("shopGalleryId")}`;
      history.push(path);
    } else if (!localStorage.getItem("shopGalleryId") && cart.items[0]) {
      path = `/gallery/${
        cart.items[0].groupId
      }?accessKey=${cart.items[0].accessKey}`;
      history.push(path);
    } else {
      path = "/";
      history.push(path);
    }

    // const path = localStorage.getItem("shopId")
    //   ? `/gallery/${localStorage.getItem("shopId")}`
    //   : "/";
    // history.push(path);
  };

  const decQuantity = (item) => {
    if (item.quantity === 1) {
      setAlert(true);
      setActualItem(item);
    }
    if (alert || item.quantity > 1) {
      dispatch(decrementAmountOfItem({ itemToDecrement: item }));
    }
  };

  useEffect(() => {
    document.body.style.overflow = "visible";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (cart?.shopId) {
      localStorage.setItem("shopId", cart?.shopId);
      dispatch(setShop(cart?.shopId));
    }
  }, [cart]);

  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - Kosár</title>
        <meta
          name="description"
          content="Véglegesítse a digitális és nyomtatott képek megrendelését. Módosítsa a meglévő kosár tartalmát."
        />
      </Helmet>
      <OuterCartWrapper>
        <Zoom in={true} timeout={700} unmountOnExit>
          <CartWrapper className={classes.card}>
            {/* 
          Hiding this is necessary for now, in multivendor version it is solved already
          <CartWrapperBack />
        <CartWrapperBack2 /> */}
            <ItemsWrapper className={classes.cart}>
              <HeaderAndItems>
                <StyledHeader>
                  <Typography component="h2" className={classes.title}>
                    Az ön kosara
                  </Typography>
                  <Typography
                    component="h4"
                    className={classes.headerItemCount}
                  >
                    {totalQuantity > 0 ? `${totalQuantity} termék` : ""}
                  </Typography>
                </StyledHeader>
                <GroupList>
                  {totalQuantity > 0 ? (
                    Object.keys(groups)?.map((key, idx) => {
                      return (
                        <GroupWrapper key={idx}>
                          <Typography
                            noWrap
                            className={classes.groupName}
                            component="span"
                          >
                            {key}
                          </Typography>
                          <ItemList>
                            {groups[key]?.map((item, idx) => (
                              <span key={idx}>
                                <CartItem
                                  groupName={item.groupName}
                                  name={item.productName}
                                  thumbnail={item.thumbnailURL || background}
                                  id={item.modifiersText}
                                  category={item?.modifierUri}
                                  size={""}
                                  count={item.quantity}
                                  decrement={() => decQuantity(item)}
                                  increment={() => incQuantity(item)}
                                  total={
                                    item.unitPrice.grossAmount?.toFixed(0) *
                                    item.quantity
                                  }
                                  delete={() => toggleAlert(item)}
                                />
                              </span>
                            ))}
                          </ItemList>
                        </GroupWrapper>
                      );
                    })
                  ) : (
                    <Typography variant="body1" className={classes.emptyCart}>
                      Az ön kosara üres.
                    </Typography>
                  )}
                </GroupList>
              </HeaderAndItems>
              <ButtonsWrapper>
                <Button
                  className={classes.backToShop}
                  onClick={() => handleBackToStore()}
                >
                  Vissza a boltba
                </Button>
                {totalQuantity > 0 && (
                  <Button
                    className={classes.backToShop}
                    onClick={() => handleEmptyCart()}
                  >
                    Kosár ürítése
                  </Button>
                )}
              </ButtonsWrapper>
            </ItemsWrapper>
            <SummaryWrapper className={classes.summary}>
              <SummaryItem>
                <StyledHeader>
                  <Typography component="h2" className={classes.summaryTitle}>
                    Összegzés
                  </Typography>
                </StyledHeader>
              </SummaryItem>
              <SummaryItem>
                <SummaryData>
                  <Typography
                    component="h3"
                    variant="body1"
                    className={classes.subtitle}
                  >
                    {countTotalQuantity()} termék
                  </Typography>
                  <Typography
                    component="h3"
                    variant="body1"
                    className={classes.subtitle}
                  >
                    {cart.totalPrice.grossAmount?.toFixed(0)} HUF
                  </Typography>
                </SummaryData>
              </SummaryItem>
              <SummaryItem>
                <TotalWrapper>
                  <TotalTitleWrapper>
                    <Typography
                      component="h3"
                      variant="body1"
                      className={classes.subtitle}
                    >
                      Teljes összeg
                    </Typography>
                  </TotalTitleWrapper>
                  <TotalPriceWrapper>
                    <Typography
                      className={classes.subtitle}
                      component="h3"
                      variant="body1"
                    >
                      {cart.totalPrice.grossAmount?.toFixed(0)} HUF
                    </Typography>
                  </TotalPriceWrapper>
                </TotalWrapper>
              </SummaryItem>
              <SummaryItem>
                <Button
                  variant="contained"
                  onClick={() => history.push("/order-checkout")}
                  className={classes.customButton}
                  disabled={
                    totalQuantity <= 0 ||
                    actions?.find((action) => action === "DISABLE_PAYMENT")
                  }
                >
                  {totalQuantity <= 0
                    ? "Az ön kosara üres."
                    : actions?.find((action) => action === "DISABLE_PAYMENT")
                    ? "Fizetés nem elérhető"
                    : "Tovább a fizetéshez"}
                </Button>
              </SummaryItem>
            </SummaryWrapper>
          </CartWrapper>
        </Zoom>

        <Dialog
          open={alert}
          onClose={() => setAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            className={classes.customDialogTitle}
            id="alert-dialog-title"
          >
            {"Biztosan törölni szeretnéd?"}
          </DialogTitle>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.customButton}
              onClick={() => toggleAlert(actualItem)}
              color="primary"
            >
              Törlés
            </Button>
            <Button
              className={classes.customButton}
              onClick={() => setAlert(false)}
              color="primary"
            >
              Mégse
            </Button>
          </DialogActions>
        </Dialog>
      </OuterCartWrapper>
    </main>
  );
};

export default Cart;
