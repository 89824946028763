const sizes = {
    XS: 375,
    SM: 768,
    MD: 1100,
    LG: 1366,
    XL: 1920 
}

export const DRAWER_WIDTH = 240

export default sizes