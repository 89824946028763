import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
//import sizes from "constants/sizes";

export const IntroButton = styled.button`
  background:${({ theme }) => theme.palette.primary.backgroundPrimary};
  color: ${({ theme }) => theme.palette.primary.textPrimary};
  border: none;
  height: 56px;
  padding: 0 2rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-Radius: 5px;
  font-size: 1rem;
  align-self: center;
  transition: background 300ms;
  font-family:${({ theme }) => theme.typography.main}, Sans-serif, Arial;
  margin-top: 1rem;

  &:hover {
    opacity: 80%;
  },`;

export const OtherButton = styled.button`
  color: ${({ theme }) => theme.palette.primary.backgroundPrimary};
  border: 1px solid ${({ theme }) => theme.palette.primary.backgroundPrimary};
  height: 56px;
  padding: 0 2rem;
  background: white;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-Radius: 5px;
  font-size: 1rem;
  align-self: center;
  transition: background 300ms;
  font-family:${({ theme }) => theme.typography.main}, Sans-serif, Arial;
  margin-top: 1rem;

  &:hover {
    opacity: 80%;
  },`;

  export const BlendeImageWrapper = styled.div`
    display: hidden;
    position: absolute;
    @media (min-width: 1250px) {
        display: block;
        height: 980px;
        width: 980px;
        bottom: 0;
        right: 0rem;
        position: absolute;
    }
`;
export const BlendeImageWrapper2 = styled.div`
  display: hidden;
  position: absolute;
  @media (min-width: 1250px) {
    display: block;
    height: 50%;
    width: 250px;
    top: 0;
    left: 0;
  }
`;
export const StyledLink = styled.a`
  color: ${({ theme }) => theme.palette.primary.backgroundSecondary};
`;

export const useStyles = makeStyles((theme) => ({
  list: {
    listStyleImage: `linear-gradient(to left bottom, ${theme.palette.primary.backgroundSecondary}, ${theme.palette.primary.backgroundSecondary})`,
    display: "flex",
    flexDirection: "column",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    "& li": {
      marginTop: "0.5rem",
    },
  },
}));
