/** @format */

import { makeStyles } from "@material-ui/core/styles";
import sizes from "constants/sizes";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        color: theme.palette.primary.textDark,
        fontWeight: "bold",
        textTransform: "uppercase",
        fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
        marginTop: "20px",
    },
    deliveryMethod: {
        marginTop: "1rem",
    },
    btnGroup: {
        position: "absolute",
        bottom: "20px",
        left: "0",
        flexDirection: "row",
        alignItems: "center",
    },
    radio: {
        color: theme.palette.primary,
    },
    button: {
        background: theme.palette.primary.backgroundSecondary,
        color: theme.palette.primary.textSecondary,
        "&:hover": {
            background: theme.palette.primary.backgroundPrimary,
        },
    },
    retryBtn: {
        borderRadius: "20px",
        border: "transparent",
        width: "60px",
        opacity: "80%",
        height: "30px",
        transition: "opacity 300ms",
        "&:hover": {
            cursor: "pointer",
            opacity: "100%",
        },
    },
    failIcon: {
        color: "whitesmoke",
        fontSize: "50px",
        animation: "$fail 1s infinite",
    },
    cardTitle: {
        color: "black",
    },
    successCheckCircle: {
        strokeDasharray: "166",
        strokeDashoffset: "166",
        strokeWidth: "2",
        strokeMiterlimit: "10",
        stroke: theme.palette.primary.success,
        fill: "none",
        animation: "stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards",
    },
    successCheck: {
        width: "70px",
        height: "70px",
        borderRadius: "50%",
        display: "block",
        strokeWidth: "2",
        stroke: "whitesmoke",
        strokeMiterlimit: "10",
        margin: "10% auto",
        boxShadow: `inset 0px 0px 0px ${theme.palette.primary.success}`,
        animation:
            "$fill .4s ease-in-out .4s forwards, $scale .3s ease-in-out .9s both",
    },
    succcesCheckCheck: {
        transformOrigin: "50% 50%",
        strokeDasharray: "48",
        strokeDashoffset: "48",
        animation: "$stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards",
    },
    fail: {
        background: "crimson",
        borderRadius: "50%",
        width: "70px",
        height: "70px",
    },
    failedBody: {
        height: "300px",
    },
    "@keyframes stroke": {
        "100%": {
            strokeDashoffset: "0",
        },
    },
    "@keyframes scale": {
        "100%": {
            transform: "none",
        },
        "50%": {
            transform: "scale3d(1.1, 1.1, 1)",
        },
    },
    "@keyframes fill": {
        "100%": {
            boxShadow: "inset 0px 0px 0px 40px #7ac142",
        },
    },
    "@keyframes fail": {
        "50%": {
            fontSize: "60px",
        },
    },
}));

export const PDetailsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
`;
export const PDetailsCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 800px;
    height: 700px;
    position: relative;
    z-index: 1;
`;
export const CardBack = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    background: ${(props) => props.theme.palette.primary.backgroundSecondary};
    opacity: 35%;
    position: absolute;
    transform: rotate(-1deg);
    z-index: -1;
    left: 2rem;
    display: none;
    @media (min-width: ${sizes.MD}px) {
        display: block;
    }
`;
export const CardBack2 = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    background: ${(props) => props.theme.palette.primary.backgroundSecondary};
    opacity: 20%;
    position: absolute;
    transform: rotate(-1deg);
    z-index: -1;
    top: 2rem;
    left: 3rem;
    display: none;
    @media (min-width: ${sizes.MD}px) {
        display: block;
    }
`;

export const PDetailsTitle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 40px 0 0 0;
    margin: auto 0;
    font-family: ${({ theme }) => theme.typography.main}, Sans-serif, Arial;
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PDetailsBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 1rem;
    max-width: 800px;
    background: rgb(230, 230, 230);
    margin-bottom: 1rem;
`;

export const IdText = styled.p`
    text-align: center;
    width: 80%;
    font-family: ${({ theme }) =>
        `${theme.typography.secondary}, Sans-serif, Arial`};
    margin-bottom: 0.2rem;
    margin-top: 0;
`;
export const DetailsTitle = styled.h1`
    font-family: ${({ theme }) =>
        `${theme.typography.secondary}, Sans-serif, Arial`};
`;
export const DetailsMessage = styled.p`
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
    width: 100%;
    font-family: ${({ theme }) =>
        `${theme.typography.secondary}, Sans-serif, Arial`};
`;

export const LoadingMessage = styled.div`
    self-align: center;
    text-align: center;
    font-family: ${({ theme }) =>
        `${theme.typography.secondary}, Sans-serif, Arial`};
`;
export const HomeBtn = styled.a`
    margin: auto 0;
    width: 300px;
    text-align: center;
    font-size: 1.5rem;
    border: 1px solid lightgrey;
    border-radius: 11px;
    background: ${({ theme }) => theme.palette.primary.backgroundSecondary};
    color: ${({ theme }) => theme.palette.primary.textSecondary};
    padding: 0.25rem 0;
    transition: 0.2s linear;
    text-decoration: none;
    font-family: ${({ theme }) =>
        `${theme.typography.secondary}, Sans-serif, Arial`};

    &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.palette.primary.backgroundPrimary};
        color: ${({ theme }) => theme.palette.primary.textSecondary};
        transition: 0.2s linear;
    }
`;

export const RetryText = styled.p`
    margin: auto 0;
    margin-top: 40px;
    width: 300px;
    text-align: center;
    font-size: 2rem;
    border: 1px solid lightgrey;
    border-radius: 11px;
    background: ${(props) => props.theme.palette.primary.backgroundSecondary};
    color: ${(props) => props.theme.palette.primary.textSecondary};
    padding: 0.25rem 0;
    transition: 0.2s linear;
    font-family: ${({ theme }) => theme.typography.main}, Sans-serif, Arial;

    &:hover {
        cursor: pointer;
        background: ${(props) =>
            props.theme.palette.primary.backgroundSecondary};
        color: ${(props) => props.theme.palette.primary.textSecondary};
        transition: 0.2s linear;
    }
`;
