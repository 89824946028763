import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    maxWidth: '500px',
    height: '100%',
    maxHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `${theme.palette.primary.backgroundSecondary} !important`,
    borderRadius: `2rem !important`
  }
}));

export const MainContainer = styled.div`
  height: 100vh;
  max-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.h1`
  color: whitesmoke;
  width: 90%;
`;
export const Desc = styled.p`
  width: 90%;
  color: whitesmoke;
  margin-bottom: 2rem;
`;

export const PortraitInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.primary.backgroundContent};
  border-radius: 50%;
  overflow: hidden;
  width: 120px;
  height: 120px;
`;

export const PortraitImage = styled.img`
  width: 60vw;
  max-width: 100px;
  margin: 0 auto;
`;
