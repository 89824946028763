import styled from "styled-components";
import { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

export const Title = styled.h1`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.backgroundPrimary};
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
`;

export const MyStepper = styled.div`
  display: flex;
`;

export const StepIndicator = styled.div`
  margin: 2rem 0.3rem 0 0.3rem;
  width: 7px;
  height: 7px;
  border-radius: 7px;
`;

export const Description = styled.h1`
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.backgroundPrimary};
  margin: 1rem 1rem 0.5rem 1rem;
  text-transform: none;
  font-family: ${(props) => props.theme.typography.secondary}, Sans-serif, Arial;
  display: block;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const useStyles = makeStyles((theme) => {
  return {
    dialog: {
      "& .MuiDialog-paper": {
        width: "100%",
        maxWidth: "1000px",
        padding: "2rem",
        margin: "1.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: "20",
        background: theme.palette.primary.textPrimary,
      },

      backdropFilter: "blur(1px)",
      backgroundColor: "rgba(0,0,30,0.22)",
    },
    dialogContent: {
      padding: "0",
    },
    dialogContext: {
      marginTop: "1rem",
      fontWeight: "bold",
      textTransform: "uppercase !important",
      fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
      width: "100%",
    },

    backBtn: {
      width: "100%",
      color: `${theme.palette.primary.textPrimary}`,
      opacity: "0.8",
      transition: "300ms",
      margin: "2rem 1rem 0 1rem",
      "&:hover": {
        opacity: "1",
        background: `${theme.palette.primary.backgroundPrimary}`,
      },
    },

    link: {
      color: 'blue',
      textDecoration: 'underline',
      "&:hover": {
        color: 'purple',
      },
      cursor: 'pointer'
    },

    btn: {
      width: "100%",
      color: `${theme.palette.primary.textPrimary}`,
      background: `${theme.palette.primary.backgroundPrimary}`,
      opacity: "0.8",
      transition: "300ms",
      margin: "2rem 1rem 0 1rem",
      "&:hover": {
        opacity: "1",
        background: `${theme.palette.primary.backgroundPrimary}`,
      },
    },
  };
});
