/** @format */

import { useTheme } from '@material-ui/core';
import blende_grey from '../../../../assets/images/svg/blende_grey.svg';
import {
  IntroSectionWrapper,
  IntroSectionDiv,
  PortraitWrapper,
  PortraitInnerWrapper,
  PortraitImage,
  Name,
  useStyles,
  BlendeImageWrapper,
  BlendeImageWrapper2,
  HeroSection,
  ProcessContainer,
  ProcessIcons,  
} from './styles';
import GroupCodeField from 'components/Group/GroupCodeField/GroupCodeField';
import logoSvg from 'assets/images/svg/iskolaevkonyv_logo_szines-feliratnelkul.svg';
import Center from 'components/General/center/Center';
import { MdOutlinePassword, MdOutlinePayment, MdSearch, MdShoppingCartCheckout } from 'react-icons/md';

const IntroSection = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <IntroSectionWrapper className={classes.introSection}>
      <IntroSectionDiv>
        <Center element='section'>
          <HeroSection>
            <div className={classes.introText}>
              <PortraitWrapper>
                <PortraitInnerWrapper>
                  <PortraitImage src={logoSvg} alt='Portrait of the photographer' />
                </PortraitInnerWrapper>
                <Name className={classes.introTitle}>Iskolaévkönyv</Name>
              </PortraitWrapper>
              <h1 className={classes.secondIntroTitle}>
                100%-ban digitalizált
                <br /> iskolai fotózás
              </h1>
              {/* <p>
                Rendeléshez kérjük add meg a kapott hozzáférési kódot, majd
                kattints a Belépésre, további információkért pedig görgess
                lejjebb!
              </p> */}
              <GroupCodeField />
            </div>
            <div className={classes.processPlusLinkWrapper}>
              <ProcessContainer>
                <div style={{ display: 'flex' }}>
                  <p style={{ marginRight: '0.5rem', color: 'white' }}>1.</p>
                  <ProcessIcons>
                    <MdOutlinePassword size={30} />
                  </ProcessIcons>
                </div>

                <div style={{ display: 'flex' }}>
                  <p style={{ marginRight: '0.5rem', color: 'white' }}>2.</p>
                  <ProcessIcons>
                    <MdSearch size={30} />
                  </ProcessIcons>
                </div>

                <div style={{ display: 'flex' }}>
                  <p style={{ marginRight: '0.5rem', color: 'white' }}>3.</p>
                  <ProcessIcons>
                    <MdShoppingCartCheckout size={30} />
                  </ProcessIcons>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ marginRight: '0.5rem', color: 'white' }}>4.</p>
                  <ProcessIcons>
                    <MdOutlinePayment size={30} />
                  </ProcessIcons>
                </div>               
              </ProcessContainer>              
            </div>
          </HeroSection>
        </Center>

        <BlendeImageWrapper2
          style={{
            background: `url(${blende_grey})`,
            backgroundSize: '200%',
            backgroundPositionX: '-12rem',
            backgroundPositionY: '-8rem'
          }}
        />
        <BlendeImageWrapper
          style={{
            background: `url(${blende_grey})`,
            backgroundSize: '172%'
          }}
        />
      </IntroSectionDiv>
    </IntroSectionWrapper>
  );
};

export default IntroSection;
