/** @format */

import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import SIZES from "constants/sizes";

export const PaymentVendorLogoWrapper = styled.div`
    background: url("../../assets/icons/tiktok_icon.png");
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const PaymentVendorLogo = styled.img`
    width: 100%;
    max-width: 300px;
`;
export const OuterWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${(props) => props.theme.typography.secondary}, Sans-serif,
        Arial;
`;

export const FooterWrapper = styled.div`
    background: url("../../assets/icons/tiktok_icon.png");
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Sections = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${SIZES.SM}px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        place-items: start stretch;
    }

    @media (min-width: ${SIZES.MD}px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        place-items: start stretch;
    }
`;

export const StyledLink = styled.a`
    color: ${({ theme }) => theme.palette.primary.backgroundSecondary};
`;

export const SectionWrapper = styled.div`
    width: 100%;
    margin: 1rem 0;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: start;

    @media (min-width: ${SIZES.SM}) {
        margin: 1rem 3rem;
        flex-direction: column;
        align-items: start;
        padding: 1rem;
    }
`;

export const CopyrightWrapper = styled.div`
    margin: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const SectionContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`;

export const SectionTitle = styled.p`
    color: ${(props) => props.theme.textPrimary};
    font-size: 1.5rem;
    font-weight: 800;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
`;

export const TitleWrapper = styled.div`
    width: 100%;
    border-bottom: 2px solid ${(props) => props.color};
    margin-bottom: 1rem;
`;

export const SectionA = styled.a`
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    line-height: 1.5rem;
    width: 100%;
    padding-left: 0;

    &:hover,
    &:focus {
        text-decoration: underline;
        cursor: pointer;
    }
    &:focus {
        outline: none !important;
    }
    @media (min-width: ${SIZES.SM}) {
        margin: 1rem 3rem;
        flex-direction: column;
        align-items: start;
        padding: 1rem;
    }
`;
export const SectionLink = styled(RouterLink)`
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    line-height: 1.5rem;
    width: 100%;
    padding-left: 0;

    &:hover,
    &:focus {
        text-decoration: underline;
        cursor: pointer;
    }

    &:focus {
        outline: none !important;
    }
`;

export const useStyles = makeStyles((theme) => ({
    outerWrapper: {
        backgroundColor: theme.palette.primary.backgroundPrimary,
    },
    link: {
        color: theme.palette.primary.textPrimary,
        outline: "none !important",
    },
    shopLink: {
        color: theme.palette.primary.textPrimary,
        outline: "none !important",
        width: "95%",
    },
    title: {
        color: theme.palette.primary.textPrimary,
        outline: "none !important",
        fontFamily: theme.typography.main,
    },
    titleWrapper: {
        color: theme.palette.primary.light,
    },
}));
