import { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import { useheaderData } from "./HeaderData";
import { setActive } from "redux/slices/HeaderSlice";
import { useStyles, StyledBadge } from "./styles";

const Header = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const routeChange = (path) => {
    history.push(path);
  };

  const { HeaderData } = useheaderData(props?.shop);

  const dispatch = useDispatch();
  const items = useSelector(
    (state) => state.nonPersistedReducers.cartSliceReducer.cart.items
  );

  const [badgeCount, setBadgeCount] = useState(0);

  useEffect(() => {
    let sum = 0;
    items?.forEach((item) => {
      sum += item.quantity;
    });
    setBadgeCount(sum);
  }, [items]);

  const curActive = useSelector(
    (state) => state.nonPersistedReducers.headerSliceReducer.active
  );

  // Temporary drawer
  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(!drawer);
  };

  const smallList = () => (
    <div className={classes.smallList}>
      <List>
        {HeaderData.options.map((option, idx) => (
          <Fragment key={`header_small_option_${idx}`}>
            <>
              {option.divider && (
                <>
                  <Divider />
                </>
              )}
            </>
            {option.external ? (
              <ListItem
                button
                disabled={option.disabled}
                className={classes.listItem}
                component={"a"}
                href={option.to}
                target={"_blank"}
                onClick={() => {
                  option.onClick(dispatch, setActive, idx);
                  setDrawer(!drawer);
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {option.icon}
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={option.name}
                />
              </ListItem>
            ) : (
              <ListItem
                button
                disabled={option.disabled}
                className={classes.listItem}
                component={RouterLink}
                to={option.to}
                onClick={() => {
                  option.onClick(dispatch, setActive, idx);
                  setDrawer(!drawer);
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {option.icon}
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={option.name}
                />
              </ListItem>
            )}
          </Fragment>
        ))}
      </List>
    </div>
  );

  const bigList = () => (
    <List component="nav" className={classes.bigList}>
      {HeaderData.options.map((option, idx) =>
        option.external ? (
          // <a target={"_blank"}  href={option.to}>{option.name}</a>
          <ListItem
            disabled={option.disabled}
            className={
              curActive === idx ? classes.activeItem : classes.listItem
            }
            component={"a"}
            href={option.to}
            target={"_blank"}
            key={`header_big_option_${idx}`}
            onClick={() => option.onClick(dispatch, setActive, idx)}
          >
            <ListItemText
              classes={{ primary: classes.bigListItemText }}
              primary={option.name}
            />
          </ListItem>
        ) : (
          <ListItem
            disabled={option.disabled}
            className={
              curActive === idx ? classes.activeItem : classes.listItem
            }
            component={RouterLink}
            to={option.to}
            key={`header_big_option_${idx}`}
            onClick={() => option.onClick(dispatch, setActive, idx)}
          >
            <ListItemText
              classes={{ primary: classes.bigListItemText }}
              primary={option.name}
            />
          </ListItem>
        )
      )}
      <IconButton
        aria-label="cart"
        className={classes.listItemIcon}
        onClick={() => {
          routeChange(`/cart`);
          dispatch(setActive(null));
        }}
      >
        <StyledBadge
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          classes={{ badge: classes.badge }}
          badgeContent={badgeCount}
        >
          <ShoppingCartIcon className={classes.listItemIcon} />
        </StyledBadge>
      </IconButton>
    </List>
  );

  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar}>
        <Toolbar disableGutters className={classes.toolbar}>
          {HeaderData.icon ? (
            <RouterLink variant="h5" className={classes.titleWrapper} to={"/"}>
              <img
                src={HeaderData.icon}
                alt="Iskolaévkönyv logo"
                className={classes.title}
              />
            </RouterLink>
          ) : (
            <RouterLink to="/" className={classes.titleWrapper}>
              <Typography variant="h4" className={classes.title}>
                {HeaderData.siteName}
              </Typography>
            </RouterLink>
          )}
          <div className={classes.bigMenu}>{bigList()}</div>
          <div className={classes.smallMenu}>
            <IconButton
              onClick={toggleDrawer()}
              edge="start"
              className={classes.listItemtext}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
            <IconButton
              aria-label="cart"
              className={classes.listItemIcon}
              onClick={() => {
                routeChange(`/cart`);
                dispatch(setActive(null));
              }}
            >
              <StyledBadge
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                badgeContent={badgeCount}
              >
                <ShoppingCartIcon className={classes.listItemIcon} />
              </StyledBadge>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div>
        {
          <Fragment>
            <Drawer
              classes={{ paper: classes.paper }}
              anchor="left"
              open={drawer}
              onClose={toggleDrawer()}
            >
              {smallList()}
            </Drawer>
          </Fragment>
        }
      </div>
    </div>
  );
};

export default Header;
