import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { getProducts } from '../../api/queries/ProductQueries';
import { useInfiniteScrollObserver } from './hooks/useInfiniteScrollObserver';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@material-ui/core';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { useGroupAPI } from './hooks/useGroupAPI';

const batchSize = 1;

export const useGroupLogic = () => {
  const paramAccessKey = useLocation().search.split('=')[1];
  const location = useLocation();
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);
  const theme = useTheme();
  const classes = useStyles(theme);
  const [urlAccessKey, setUrlAccesskey] = useState(null);
  const { idAndAccessKey } = useParams();
  const { fetchHeader, header, headerLoading, fetchGroups, groups, loading, fetchCover, cover } = useGroupAPI();
  const [id, setId] = useState(null);
  const history = useHistory();
  const mobile = useMediaQuery('(max-width: 480px)');
  const tablet = useMediaQuery('(min-width: 480px)');
  const desktop = useMediaQuery('(min-width: 992px)');
  const desktopXl = useMediaQuery('(min-width: 1200px)');
  const desktopXXl = useMediaQuery('(min-width: 1921px)');

  const queryClient = useQueryClient();

  const getUriId = (segment) => {
    if (segment) {
      return +segment?.match('mod#id:[0-9]*')[0]?.split(':')[1];
    }
  };

  const getValidUntilFields = (modifiers) => {
    let validUntilP = 'Lejárt';
    let validUntilD = 'Lejárt';
    let validUntilPr = 'Nincs';
    if (
      header?.productGroupModifierValidities?.find(
        (el) =>
          getUriId(
            modifiers?.find(
              (m) =>
                m.priority === 1 &&
                (m.modifierUriSegment.includes('lelkesprint') ||
                  m.modifierUriSegment.includes('paper') ||
                  m?.modifierUriSegment?.includes('postAndLelkes')) &&
                !m.name.includes('Ajándék tárgy')
            )?.modifierUriSegment
          ) === getUriId(el.modifierUriSegment)
      )
    ) {
      validUntilP = header?.productGroupModifierValidities
        ?.findLast(
          (el) =>
            getUriId(
              modifiers?.find(
                (m) =>
                  m.priority === 1 &&
                  (m.modifierUriSegment.includes('lelkesprint') ||
                    m.modifierUriSegment.includes('paper') ||
                    m?.modifierUriSegment?.includes('postAndLelkes'))
              )?.modifierUriSegment
            ) === getUriId(el.modifierUriSegment)
        )
        ?.validUntil?.substring(0, 10);
    }
    if (
      header?.productGroupModifierValidities?.find(
        (el) =>
          getUriId(
            modifiers?.find((m) => m.priority === 1 && m.modifierUriSegment.includes('email'))?.modifierUriSegment
          ) === getUriId(el.modifierUriSegment)
      )
    ) {
      validUntilD = header?.productGroupModifierValidities
        ?.findLast(
          (el) =>
            getUriId(
              modifiers?.find((m) => m.priority === 1 && m.modifierUriSegment.includes('email'))?.modifierUriSegment
            ) === getUriId(el.modifierUriSegment)
        )
        ?.validUntil?.substring(0, 10);
    }
    if (
      header?.productGroupModifierValidities?.find(
        (el) =>
          getUriId(modifiers?.find((m) => m.priority === 1 && m.name.includes('Ajándék tárgy'))?.modifierUriSegment) ===
          getUriId(el.modifierUriSegment)
      )
    ) {
      validUntilPr = 'Lejárt';
      validUntilPr = header?.productGroupModifierValidities
        ?.findLast(
          (el) =>
            getUriId(
              modifiers?.find(
                (m) =>
                  m.priority === 1 && m.modifierUriSegment.includes('lelkesprint') && m.name.includes('Ajándék tárgy')
              )?.modifierUriSegment
            ) === getUriId(el.modifierUriSegment)
        )
        ?.validUntil?.substring(0, 10);
    }
    return (
      <>
        <Typography className={classes.validUntil}>
          Nyomtatott képek rendelési határideje:{' '}
          <label
            style={{
              color: theme.palette.primary.backgroundSecondary
            }}>
            {validUntilP}
          </label>
        </Typography>
        <Typography className={classes.validUntil}>
          Digitális képek rendelési határideje:{' '}
          <label
            style={{
              color: theme.palette.primary.backgroundSecondary
            }}>
            {validUntilD}
          </label>
        </Typography>
        {validUntilPr === 'Nincs' ? (
          ''
        ) : (
          <Typography className={classes.validUntil}>
            Ajándéktárgyak rendelési határideje:{' '}
            <label
              style={{
                color: theme.palette.primary.backgroundSecondary
              }}>
              {validUntilPr}
            </label>
          </Typography>
        )}
      </>
    );
  };

  useEffect(() => {
    if (idAndAccessKey) {
      const [id, acString] = idAndAccessKey.split('&');
      setId(id);
      if (acString) {
        const accessKeyValue = acString.split('=')[1];
        setUrlAccesskey(accessKeyValue);
      }
    }
  }, [idAndAccessKey]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflowY = 'visible';
  }, [header?.id]);

  const keys = useSelector((state) => state.nonPersistedReducers.accessKeySliceReducer.accessKeys);

  const getQdakAccessKey = () => {
    const loadedAccessKey = keys[id];
    const parentId = localStorage.getItem('parentGroupId');
    // Loading previously used accesskey.
    if (typeof loadedAccessKey !== 'undefined') {
      return loadedAccessKey.toString();
      // Using the currently received accessKey
    } else if (history.location?.state?.accessKey) {
      return history.location?.state?.accessKey;
      // Using the accessKey of the parent
    } else if (parentId) {
      let loadedAccessKeyForChildGroup = keys[parentId];
      // Loading previously used accesskey.
      if (typeof loadedAccessKeyForChildGroup !== 'undefined') {
        return loadedAccessKeyForChildGroup.toString();
        // Using the currently received accessKey
      } else {
        return null;
      }
    } else if (urlAccessKey) {
      return urlAccessKey;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const qdakAccessKey = getQdakAccessKey();

    if (id && !qdakAccessKey && !paramAccessKey && !localStorage.getItem(id)) {
      history.push('/');
    } else if (id && qdakAccessKey) {
      if (id !== null && (shopId !== null || localStorage.getItem('shopId'))) {
        fetchHeader({
          shopId: location.search === '' && shopId ? shopId : localStorage.getItem('shopId'),
          accessKey: qdakAccessKey,
          id: id
        });
        fetchCover({
          shopId: location.search === '' && shopId ? shopId : localStorage.getItem('shopId'),
          id: id
        });
        fetchGroups({
          shopId: location.search === '' && shopId ? shopId : localStorage.getItem('shopId'),
          accessKey: qdakAccessKey,
          id: id
        });
      }
    } else if (id && paramAccessKey) {
      if (id !== null && (shopId !== null || localStorage.getItem('shopId'))) {
        fetchHeader({
          shopId: location.search === '' && shopId ? shopId : localStorage.getItem('shopId'),
          accessKey: paramAccessKey,
          id: id
        });
        fetchCover({
          shopId: location.search === '' && shopId ? shopId : localStorage.getItem('shopId'),
          id: id
        });
        fetchGroups({
          shopId: location.search === '' && shopId ? shopId : localStorage.getItem('shopId'),
          accessKey: paramAccessKey,
          id: id
        });
      }
    } else if (id && localStorage.getItem(id)) {
      if (id !== null && (shopId !== null || localStorage.getItem('shopId'))) {
        fetchHeader({
          shopId: location.search === '' && shopId ? shopId : localStorage.getItem('shopId'),
          accessKey: localStorage.getItem(id),
          id: id
        });
        fetchCover({
          shopId: location.search === '' && shopId ? shopId : localStorage.getItem('shopId'),
          id: id
        });
        fetchGroups({
          shopId: location.search === '' && shopId ? shopId : localStorage.getItem('shopId'),
          accessKey: localStorage.getItem(id),
          id: id
        });
      }
    }
    if (shopId !== -1) {
      localStorage.setItem('shopGalleryId', id);
      localStorage.setItem('shopId', shopId);
    }
  }, [id, shopId]);

  //Infinite product fetching
  const {
    data: infiniteProducts,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery({
    queryKey: ['purchasableProducts', id],
    getNextPageParam: (prevData) => {
      return prevData?.data?.page?.number < prevData?.data?.page?.totalPages
        ? prevData?.data?.page?.number + 1
        : undefined;
    },
    queryFn: ({ pageParam = 1 }) => {
      return getProducts({
        shopId: location.search === '' && shopId ? shopId : localStorage.getItem('shopId'),
        accessKey: getQdakAccessKey() ? getQdakAccessKey() : paramAccessKey ? paramAccessKey : localStorage.getItem(id),
        id: id,
        queryString: { pageParam, batchSize }
      });
    },
    enabled: !!id,
    initialPageParam: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  });

  useEffect(() => {
    queryClient.clear();
  }, [id, mobile, tablet, desktop, desktopXl, desktopXXl]);

  const [stickyBreadcrumb, setStickyBreadcrumb] = useState(false);

  const changeBreadcrumb = () => {
    if (window.scrollY > 80) {
      setStickyBreadcrumb(true);
    }
    if (window.scrollY < 80) {
      setStickyBreadcrumb(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBreadcrumb);
    return () => window.removeEventListener('scroll', changeBreadcrumb);
  }, []);

  const { lastRef } = useInfiniteScrollObserver(isFetchingNextPage, hasNextPage, fetchNextPage);

  return {
    lastRef,
    infiniteProducts,
    getValidUntilFields,
    header,
    stickyBreadcrumb,
    location,
    shopId,
    cover,
    headerLoading,
    loading,
    groups,
    getQdakAccessKey,
    id,
    mobile,
    tablet,
    desktop,
    desktopXl,
    desktopXXl
  };
};
