import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Dialog,
  Button,
  DialogContent,
  DialogContentText,
  Typography,
  CircularProgress,
  useTheme,
  Zoom
} from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useProductAPI } from './hooks/useProductAPI';
import { useProductBL } from './hooks/useProductBL';
import SelectStep from './SelectStep/SelectStep';
import CountStep from './CountStep/CountStep';
import {
  DialogContentWrapper,
  Title,
  ProductName,
  Image,
  Transition,
  useStyles,
  ProductCard,
  OverlayHolder,
  HeartIcon,
  CartIconWrapper,
  DialogImage,
  ButtonsWrapper,
  MediaSection
} from './styles';
import { Skeleton, Tooltip } from '@mui/material';

import { useHistory } from 'react-router-dom';

const Product = ({ shopId, product, group, qdakAccessKey, lastRef }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const [isvisible, setIsvisible] = useState(false);
  const [isInCart, setIsInCart] = useState();
  const [cartLoading, setCartLoading] = useState(false);
  const { fetchProductById, productById, fetchProductImage, productImage } = useProductAPI();
  const {
    toBoolean,
    calcSteps,
    open,
    toggleOpen,
    addToCart,
    toggleLike,
    calcPrice,
    calcTotalPrice,
    setQuantity,
    steps,
    setSteps,
    activeStep,
    setActiveStep,
    handleNext,
    handleBack,
    liked,
    setLiked,
    updateSteps
  } = useProductBL();

  const cart = useSelector((state) => state.nonPersistedReducers.cartSliceReducer.cart);

  const searchItems = () => {
    cart.items.forEach((item) => {
      if (parseInt(item.productId) === product?.id) {
        setIsInCart(true);
      }
    });
  };

  const calcName = (name) => {
    let displayedName = name;

    if (name.length > 15) {
      displayedName = name.slice(0, 7) + '...' + name.slice(name.length - 7, name.length);
    }

    return displayedName;
  };
  useEffect(() => {
    setIsvisible(false);
    setActiveStep(0);
  }, [open]);

  useEffect(() => {
    setIsInCart(false);
    searchItems();
  }, [cart, product]);

  useEffect(() => {
    fetchProductImage({
      shopId: shopId,
      accessKey: qdakAccessKey,
      id: product?.id
    });
  }, [product?.id, qdakAccessKey]);

  useEffect(() => {
    if (open === true) {
      fetchProductById({
        shopId: shopId,
        accessKey: qdakAccessKey,
        id: product?.id
      });
    }
  }, [open]);

  useEffect(() => {
    if (!productById?.modifiers) return;
    let newSteps = calcSteps(productById);
    setActiveStep(0);
    setSteps(newSteps);
  }, [productById]);

  useEffect(() => {
    if (activeStep >= 4) {
      setCartLoading(false);
    }
  }, [activeStep]);

  useEffect(() => {
    let isLiked = localStorage.getItem(product.id) === null ? false : toBoolean(localStorage.getItem(product.id));

    setLiked(isLiked);
  }, []);

  useEffect(() => {
    localStorage.setItem(product.id, liked);
  }, [liked, product.id]);

  const [hover, setHover] = useState(false);

  return (
    <ProductCard
      theme={theme}
      hover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={toggleOpen}>
      <CartIconWrapper style={{ display: !isInCart && 'none' }}>
        <ShoppingCartIcon className={classes.basketIcon} />
      </CartIconWrapper>
      {productImage ? (
        <Image src={productImage} alt={product?.alt} ref={lastRef} />
      ) : (
        <Skeleton variant='rectangular' width={'100%'} height={'100%'} className={classes.skeleton} />
      )}

      <HeartIcon hover={hover} isLiked={liked} toggleLike={(e) => toggleLike(e, product?.id)} />

      {open && (
        <Dialog
          open={productById ? open : false}
          TransitionComponent={Transition}
          keepMounted
          className={classes.dialog}
          onClick={(e) => e.stopPropagation()}
          onClose={toggleOpen}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogContentWrapper onClick={(e) => e.stopPropagation()}>
            <MediaSection>
              <DialogImage src={productImage} alt={`${product.id}_preview`} />
              <Typography className={classes.fileName}>{product.name}</Typography>
            </MediaSection>
            <DialogContent className={classes.dialogContent}>
              <DialogContentText className={classes.dialogContext} component={'span'}>
                <Title>{group?.name}</Title>
                <Tooltip style={{ zIndex: '1' }} title={product?.name}>
                  <ProductName>{calcName(product?.name)}</ProductName>
                </Tooltip>
                Elérhető variációk
              </DialogContentText>
              {
                <Stepper className={classes.stepper} activeStep={activeStep} orientation='vertical'>
                  {steps.map((step, index) => (
                    <Step key={step.name}>
                      <StepLabel className={classes.stepLabel}>
                        <Typography className={classes.stepLabel}>
                          {step.name +
                            (step.answer === null
                              ? ' '
                              : `: ${productById?.modifiers.find((modifier) => modifier.id === step.answer)?.name} ${
                                  calcPrice(step.answer, productById) === 0
                                    ? ''
                                    : ' - ' + calcPrice(step.answer, productById).toFixed(0) + 'HUF'
                                } `)}
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        {step.options && (
                          <SelectStep
                            next={handleNext}
                            prev={handleBack}
                            toggle={toggleOpen}
                            step={step}
                            steps={steps}
                            setAnswer={(answer) => {
                              updateSteps(
                                step,
                                answer,
                                productById?.modifiers.find((modifier) => modifier.id === answer)?.deliveryTypeDTO
                              );
                            }}
                          />
                        )}
                      </StepContent>
                    </Step>
                  ))}
                  <Step key={'count_step'}>
                    <StepLabel className={classes.stepLabel}>
                      <Typography className={classes.stepLabel}>
                        {steps[0]?.deliveryType === 'EMAIL' || cartLoading ? 'Hozzáadás a kosárhoz' : 'Mennyiség'}
                      </Typography>
                    </StepLabel>
                    <StepContent className={classes.stepLabel}>
                      <CountStep cartLoading={cartLoading} format={steps[0]?.deliveryType} setQuantity={setQuantity} />
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                            Vissza
                          </Button>
                          <Button
                            disabled={cartLoading}
                            onClick={() => {
                              addToCart(group, product, productById);
                              setSteps(calcSteps(productById));
                              setIsvisible(true);
                              setCartLoading(true);
                            }}
                            className={classes.toCartBtn}>
                            {cartLoading ? <CircularProgress size={20} className={classes.spinner} /> : 'Kosárba'}
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                </Stepper>
              }
              <div style={{ marginTop: '1rem' }}>
                <Typography className={classes.totalPrice}>
                  Részösszeg: {calcTotalPrice(product, productById).toFixed(0)} HUF
                </Typography>
                <Typography className={classes.totalPrice}>
                  Teljes összeg: {cart?.totalPrice && cart?.totalPrice?.grossAmount} HUF
                </Typography>

                <ButtonsWrapper>
                  <Zoom in={cartLoading ? false : isvisible} timeout={700} unmountOnExit>
                    <div>
                      <Button
                        className={classes.button}
                        onClick={() => {
                          setIsvisible(false);
                          setActiveStep(0);
                          toggleOpen();
                        }}>
                        Tovább vásárolok
                      </Button>
                      <Button
                        className={classes.payButton}
                        onClick={() => {
                          history.push('/cart');
                        }}>
                        Fizetek
                      </Button>
                    </div>
                  </Zoom>
                </ButtonsWrapper>
              </div>
            </DialogContent>
          </DialogContentWrapper>
        </Dialog>
      )}
    </ProductCard>
  );
};

export default Product;
