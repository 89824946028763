import styled from "styled-components";
import { forwardRef } from "react";

import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import SIZES from "constants/sizes";

export const useStyles = makeStyles((theme) => ({
  customButton: {
    backgroundColor: theme.palette.primary.backgroundSecondary,
    borderColor: theme.palette.primary.backgroundSecondary,
    color: `${theme.palette.primary.textSecondary}`,
    textTransform: "uppercase",
    fontSize: ".7rem",
    letterSpacing: ".1rem",
    lineHeight: "1.54",
    width: "100%",
    transition: "250ms ease-in",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.primaryGrey,
      color: theme.palette.primary.backgroundPrimary,
    },
  },
  customDialogTitle: {
    fontSize: ".65rem",
    letterSpacing: ".1rem",
    lineHeight: "1.54",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
  },
  card: {
    margin: "2rem auto",
    maxWidth: "950px",
    heigth: "100%",
    boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "1rem",
    border: "transparent",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  link: {
    cursor: "pointer",
  },

  breadcrumb: {
    margin: "1rem",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
  },
  summary: {
    borderBottomRightRadius: "1rem",
    borderBottomLeftRadius: "1rem",
    width: "100%",
    padding: "1rem 0",
    backgroundColor: theme.palette.primary.backgroundPrimary,
    color: theme.palette.primary.dark,
    [theme.breakpoints.up("sm")]: {
      borderTopRightRadius: "1rem",
      borderBottomRightRadius: "1rem",
      borderBottomLeftRadius: "0",
      padding: "1rem 0",
      width: "100%",
    },
  },
  cart: {
    backgroundColor: theme.palette.primary.backgroundSecondary,
    width: "100%",
    padding: "1rem 0",
    borderTopRightRadius: "1rem",
    borderTopLeftRadius: "1rem",
    [theme.breakpoints.up("sm")]: {
      borderBottomLeftRadius: "1rem",
      borderTopRightRadius: "0",
      padding: "1rem 0",
      width: "100%",
    },
  },
  emptyCart: {
    margin: "0",
    fontSize: "35px",
    color: theme.palette.primary.textSecondary,
    [theme.breakpoints.up("sm")]: {
      fontSize: "40px",
      margin: "5rem 0 ",
    },
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  subtitle: {
    textTransform: "uppercase",
    color: theme.palette.primary.textPrimary,
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  title: {
    fontSize: "1.5rem",
    padding: "0",
    textTransform: "uppercase",
    color: theme.palette.primary.textSecondary,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
  },
  summaryTitle: {
    fontSize: "1.5rem",
    padding: "0",
    textTransform: "uppercase",
    color: theme.palette.primary.textPrimary,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
  },
  headerItemCount: {
    fontSize: "1rem",
    textTransform: "uppercase",
    color: theme.palette.primary.textSecondary,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
  },
  groupName: {
    color: theme.palette.primary.textSecondary,
    fontWeight: 400,
    fontSize: "1rem",
    alignSelf: "flex-start",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    margin: "0 0 0 0",
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  dialog: {
    width: "100%",
  },
  fullAmount: {
    fontWeight: "400",
  },
  dialogActions: {
    margin: "0 auto",
  },
  backToShop: {
    width: "100%",
    color: theme.palette.primary.textSecondary,
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    '&:hover': {
      color: theme.palette.primary.backgroundPrimary,
    }
  },
  deliverySelect: {
    width: "100%",
  },
  deliveryOption: {
    fontSize: "1rem",
    color: theme.palette.primary.textSecondary,
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  adornment: {
    color: theme.palette.primary.textSecondary,
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
}));

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const OuterCartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  box-sizing: border-box;
  height: 100%;
  @media (min-width: ${SIZES.SM}px) {
    padding: 1rem 0;
  }
`;

export const CartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 200;
  height: 100%;
  @media (min-width: ${SIZES.SM}px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
`;

export const CartWrapperBack = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  background: ${(props) => props.theme.palette.primary.backgroundSecondary};
  opacity: 35%;
  position: absolute;
  transform: rotate(-3deg);
  z-index: -1;
  left: 4rem;
  display: none;
  @media (min-width: ${SIZES.MD}px) {
    display: block;
  }
`;
export const CartWrapperBack2 = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  background: ${(props) => props.theme.palette.primary.backgroundSecondary};
  opacity: 20%;
  position: absolute;
  transform: rotate(-6deg);
  z-index: -1;
  left: 6rem;
  display: none;
  @media (min-width: ${SIZES.MD}px) {
    display: block;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
export const GroupWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const HeaderAndItems = styled.div`
  width: 90%;
`;
export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
`;

export const GroupList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SummaryWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    margin: 1rem 0;
  }
`;

export const SummaryItem = styled.div`
  width: 90%;
  padding: 0.5rem 0;
`;

export const SummaryData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TotalWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid grey;
  display: flex;
`;

export const TotalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const TotalPriceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
