import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.palette.primary.backgroundSecondary};
`;

export const HomeWrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: rgb(241, 245, 249);
  align-items: center;  
  section {
    margin: unset;
  }
`;

export const AccordionContainer = styled.div`
  margin: 2rem 0;
  @media (min-width: 480px) {
    margin: 3rem 1rem;
  }
`;
export const TypesImage = styled.img`
  margin: 0 auto;
  width: 100%;
  @media (min-width: 768px) {
    width: 60%;
  }
`;

export const useStyles = makeStyles((theme) => ({
  list: {
    listStyleImage: `linear-gradient(to left bottom, ${theme.palette.primary.backgroundSecondary}, ${theme.palette.primary.backgroundSecondary})`,
    display: "flex",
    flexDirection: "column",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    "& li": {
      marginTop: "0.5rem",
    },
  },
}));
