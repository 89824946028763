import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import cartSliceReducer from 'redux/slices/CartSlice';
import headerSliceReducer from 'redux/slices/HeaderSlice';
import accessKeySliceReducer from 'redux/slices/AccessKeySlice';
import shopSliceReducer from 'redux/slices/ShopSlice';
import documentSliceReducer from 'redux/slices/DocumentSlice';

//const persistedReducers = persistReducer(persistConfig, reducers); // Persisted

const nonPersistedReducers = combineReducers({
  headerSliceReducer,
  cartSliceReducer,
  accessKeySliceReducer,
  shopSliceReducer,
  documentSliceReducer
}); // NON persisted, such as API calls (making them persistent causes bugs)

const combinedReducers = combineReducers({
  /* persistedReducers ,*/ nonPersistedReducers
}); // The "final" combined reducer, containing all our reducers

const store = configureStore({
  reducer: combinedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export default store;
