import React, { useState } from "react";
import styles from "./BlogThumbnail.module.css";
import { Skeleton } from "@mui/material";

const BlogThumbnail = ({ url = "", imgAlt = "" }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className={styles.thumbnail}>
      {isLoading && (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"100%"}
          className={styles.skeleton}
        />
      )}
      <img
        src={`data:image/png;base64,${url}`}
        onLoad={() => setIsLoading(false)}
        style={{ visibility: isLoading ? "hidden" : "visible" }}
        alt={`${imgAlt} cikk borítóképe`}
        loading="lazy"
        width="300px"
        height="200px"
      />
    </div>
  );
};

export default BlogThumbnail;
