/** @format */

import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";

import { useOrderAPI } from "./hooks/useOrderAPI";
import { useStyles } from "./styles";
import BillingForm from "./BillingForm/BillingForm";
import AddressForm from "./AddressForm/AddressForm";
//import PaymentForm from "./PaymentForm/PaymentForm";
import Review from "./Review/Review";
import { usePaymentAPI } from "./hooks/usePaymentAPI";
import { useDeliveryAPI } from "./hooks/useDeliveryAPI";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import TransferDetails from "./TransferDetails/TransferDetails";

const Checkout = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const cart = useSelector(
        (state) => state.nonPersistedReducers.cartSliceReducer.cart
    );

    const [deliveryTargets, setDeliveryTargets] = useState();
    const [deliveryTargetObjects, setDeliveryTargetObjects] = useState();

    const { postOrder, loading, submitted, order } = useOrderAPI(() => setActiveStep(3));
    const { paymentMethods } = usePaymentAPI();
    const {
        getDeliveryTargets,
        deliveryMethods,
        fetchItemGroup,
        itemGroup,
        schoolGroup,
        fetchSchoolGroup,
    } = useDeliveryAPI();

    useEffect(() => {
        getDeliveryTargets(
            localStorage.getItem("shopId"),
            cart,
            setDeliveryTargets,
            setDeliveryTarget,
            setDeliveryTargetObjects
        );
        fetchItemGroup({
            id: cart?.items[0]?.groupId,
            shopId: localStorage.getItem("shopId"),
        });
    }, [cart?.items[0]?.groupId]);

    useEffect(() => {
        fetchSchoolGroup({
            id: itemGroup?.belongsTo?.id,
            shopId: localStorage.getItem("shopId"),
        });
    }, [itemGroup?.belongsTo?.id]);

    useEffect(() => {
        if (
            !localStorage.getItem("cartId") ||
            typeof localStorage.getItem("cartId") === "undefined"
        ) {
            history.push("/");
        }
    }, []);

    const [billingData, setBillingData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        emailConfirm: "",
        phone: "",
        companyName: "",
        VATNumber: "",
        GroupVatNumber: "",
        SocialVATNumber: "",
        street: "",
        streetNo: "",
        city: "",
        zip: "",
        country: "Magyarország",
        isCompany: false,
    });

    const [shippingData, setShippingData] = useState({
        deliveryTarget: "",
        submitted: false,
    });

    const [deliveryTarget, setDeliveryTarget] = useState("");
    const [isCompany, setIsCompany] = useState(false);
    const [deliveryMethod, setDeliveryMethod] = useState("1");
    const [paymentMethod, setPaymentMethod] = useState("OTP_SIMPLE");

    const steps =
        paymentMethod === "TRANSFER"
            ? [
                  "Számlázási adatok",
                  "Szállítás és fizetés",
                  "Rendelés összegzése",
                  "Rendelés lezárása"
              ]
            : [
                  "Számlázási adatok",
                  "Szállítás és fizetés",
                  "Rendelés összegzése",
              ];

    const getStepContent = (step) => {if(paymentMethod === 'TRANSFER'){
      switch (step) {
            case 0:
                return (
                    <BillingForm
                        steps={steps}
                        stepNumber={step}
                        handleNext={() => setActiveStep(activeStep + 1)}
                        handleBack={handleBack}
                        isCompany={isCompany}
                        setIsCompany={setIsCompany}
                        data={billingData}
                        setData={setBillingData}
                    />
                );
            case 1:
                return (
                    <AddressForm
                        steps={steps}
                        stepNumber={step}
                        handleNext={() => setActiveStep(activeStep + 1)}
                        handleBack={handleBack}
                        deliveryTargets={deliveryTargets}
                        data={shippingData}
                        billingData={billingData}
                        setData={setShippingData}
                        deliveryTarget={deliveryTarget}
                        setDeliveryTarget={setDeliveryTarget}
                        deliveryMethod={deliveryMethod}
                        deliveryMethods={deliveryMethods}
                        paymentMethods={paymentMethods}
                        paymentMethod={paymentMethod}
                        setDeliveryMethod={setDeliveryMethod}
                        setPaymentMethod={setPaymentMethod}
                    />
                );
            case 2:
                return (
                    <Review
                        steps={steps}
                        stepNumber={step}
                        handleNext={postOrder}
                        handleBack={handleBack}
                        loading={loading}
                        submitted={submitted}
                        source={props.datasource}
                        deliveryPrice={props.deliveryPrice}
                        shippingData={{
                            ...billingData.street,
                            ...shippingData,
                        }}
                        billingData={billingData}
                        isCompany={isCompany}
                        deliveryMethods={deliveryMethods}
                        paymentMethods={paymentMethods}
                        deliveryMethod={deliveryMethod}
                        paymentMethod={paymentMethod}
                        deliveryTarget={deliveryTarget}
                        deliverySchool={schoolGroup}
                        deliveryTargetObjects={deliveryTargetObjects}
                        shopData={props?.shopData}
                    />
                );
            case 3:
              return (
                <TransferDetails
                    order={order}
                />
            );
            default:
                throw new Error("Unknown step");
        }
    } else {
      switch (step) {
        case 0:
            return (
                <BillingForm
                    steps={steps}
                    stepNumber={step}
                    handleNext={() => setActiveStep(activeStep + 1)}
                    handleBack={handleBack}
                    isCompany={isCompany}
                    setIsCompany={setIsCompany}
                    data={billingData}
                    setData={setBillingData}
                />
            );
        case 1:
            return (
                <AddressForm
                    steps={steps}
                    stepNumber={step}
                    handleNext={() => setActiveStep(activeStep + 1)}
                    handleBack={handleBack}
                    deliveryTargets={deliveryTargets}
                    data={shippingData}
                    billingData={billingData}
                    setData={setShippingData}
                    deliveryTarget={deliveryTarget}
                    setDeliveryTarget={setDeliveryTarget}
                    deliveryMethod={deliveryMethod}
                    deliveryMethods={deliveryMethods}
                    paymentMethods={paymentMethods}
                    paymentMethod={paymentMethod}
                    setDeliveryMethod={setDeliveryMethod}
                    setPaymentMethod={setPaymentMethod}
                />
            );
        case 2:
            return (
                <Review
                    steps={steps}
                    stepNumber={step}
                    handleNext={postOrder}
                    handleBack={handleBack}
                    loading={loading}
                    submitted={submitted}
                    source={props.datasource}
                    deliveryPrice={props.deliveryPrice}
                    shippingData={{
                        ...billingData.street,
                        ...shippingData,
                    }}
                    billingData={billingData}
                    isCompany={isCompany}
                    deliveryMethods={deliveryMethods}
                    paymentMethods={paymentMethods}
                    deliveryMethod={deliveryMethod}
                    paymentMethod={paymentMethod}
                    deliveryTarget={deliveryTarget}
                    deliverySchool={schoolGroup}
                    deliveryTargetObjects={deliveryTargetObjects}
                    shopData={props?.shopData}
                />
            );
        case 3:
        default:
            throw new Error("Unknown step");
    }
    }
        
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const history = useHistory();

    return (
        <main>
            <Helmet>
                <title>Iskolaévkönyv - Megrendelés</title>
                <meta
                    name="description"
                    content="A fényképek megrendeléshez szükséges adatok megadása."
                />
            </Helmet>
            <Fragment>
                <CssBaseline />
                <div className={classes.layout}>
                    <Stepper
                        activeStep={activeStep}
                        className={classes.stepper}
                        style={{
                            background: "transparent",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: 'center',
                            fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
                        }}
                    >
                        {steps.map((label) => (
                            <Step
                                key={label}
                                color={theme.palette.primary.textContent}
                            >
                                <StepLabel className={classes.stepperItems}>
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Zoom in={true} timeout={700}>
                        <Paper className={classes.paper}>
                            {/*
            Hiding this is necessary for now, in multivendor version it is solved already
            <CheckoutBack />
          <CheckoutBack2 /> */}
                            <div className={classes.paperTitle}>
                                <Typography
                                    style={{
                                        fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
                                    }}
                                    variant="h4"
                                    align="center"
                                >
                                    Rendelés
                                </Typography>
                            </div>
                            <Fragment>{getStepContent(activeStep)}</Fragment>
                        </Paper>
                    </Zoom>
                </div>
            </Fragment>
        </main>
    );
};

export default Checkout;
