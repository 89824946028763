import { Grid, TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Autocomplete } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';

import { usePostalCodeFields } from './hooks/usePostalCodeFields';
import { useStyles } from './styles';

const PostalCodeFields = ({ data, setData, formik }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { calcAddress, calcPostalCode, calcAddressSuggestions, calcZipSuggestions, zips, settlements } =
    usePostalCodeFields();

  useEffect(() => {
    calcZipSuggestions(data?.city, data?.zip);
    if (data?.zip?.length > 0) {
      const suggestion = calcAddress(data.zip);
      if (data?.city && settlements[Object.keys(settlements)?.find((key) => key === data?.city)] == data?.zip) {
        return;
      } else {
        setData({ ...data, city: suggestion || '' });
      }
    } else if (data?.city.length > 1 && Object.keys(settlements)?.find((key) => key === data?.city)) {
      setData({ ...data, city: '' });
    }
  }, [data?.zip]);

  useEffect(() => {
    calcZipSuggestions(data?.city, data?.zip);
    if (data?.city?.length > 0) {
      const suggestion = calcPostalCode(data.city, data.zip);
      setData({ ...data, zip: suggestion || '' });
    } else if (data?.zip.length > 1) {
      setData({ ...data, zip: '' });
    }
  }, [data?.city]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          disablePortal
          options={zips}
          filterOptions={(options) => options}
          inputValue={data?.zip}
          className={classes.autoComplete}
          sx={{ fontFamily: `${theme.typography.main}, Sans-serif, Arial` }}
          onChange={(e) =>
            setData({
              ...data,
              zip: e.target.innerHTML.includes('<') ? '' : e.target.innerHTML
            })
          }
          renderInput={(params) => (
            <TextField
              id='zip'
              required
              name='zip'
              value={data?.zip}
              className={classes.zip}
              {...params}
              label={'Irányítószám'}
              key='postal_code_field'
              onChange={(e) => {
                formik?.handleChange(e);
                setData({ ...data, zip: e.target.value });
              }}
              error={formik?.touched?.zip && Boolean(formik?.errors?.zip)}
              helperText={formik?.touched?.zip && formik?.errors?.zip}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          disablePortal
          options={calcAddressSuggestions(data?.city)}
          inputValue={data?.city}
          className={classes.autoComplete}
          sx={{ fontFamily: `${theme.typography.main}, Sans-serif, Arial` }}
          onChange={(e) => {
            setData({
              ...data,
              city: e.target.innerHTML.includes('<') ? '' : e.target.innerHTML
            });
          }}
          renderInput={(params) => (
            <TextField
              id='city'
              required
              name='city'
              value={data?.city}
              className={classes.city}
              {...params}
              label={'Város'}
              key='address_field'
              onChange={(e) => {
                formik?.handleChange(e);
                setData({ ...data, city: e.target.value });
              }}
              error={formik?.touched?.city && Boolean(formik?.errors?.city)}
              helperText={formik?.touched?.city && formik?.errors?.city}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default PostalCodeFields;
