import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDocumentsApi } from "hooks/useDocumentsApi";

import {
  Transition,
  Title,
  useStyles,
  MyStepper,
  Description,
  StepIndicator,
  ButtonsWrapper,
} from "./styles";

const ShopNotificationDialog = ({ open, setOpen }) => {
  // const { notification, fetchNotification } = useNotificationAPI();
  const theme = useTheme();
  const classes = useStyles(theme);
  const baseDocuments = useSelector(
    (state) => state.nonPersistedReducers?.documentSliceReducer?.documents
  );
  const { downloadBaseDocuments } = useDocumentsApi();

  const [aszfAccepted, setAszfAccepted] = useState(false);

  const notifications = [
    {
      title: "Tisztelt Vásárlók!",
      mainDesc:
        "Oldalunkról történő vásárláshoz, el kell fogadnotok Adatkezelési nyilatkozatunkat, illetve Általános szerződési feltételeinket!",
      secondaryDesc: "Megértéseteket köszönjük!",
      btnText: "Megértettem",
      skippable: true,
    },
    {
      title: "Tisztelt Vásárlók!",
      mainDesc:
        "Nyomtatott képek rendelésére, azok rendelési határidejéig van lehetőség. Ezután már csak digitális formátumban vásárolhatók meg a képek, a digitális képek lejárati határidejéig.",
      secondaryDesc: "Köszönjük!",
      btnText: "Megértettem",
      skippable: true,
    },
  ];

  const [activeStep, setActiveStep] = useState(0);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        className={classes.dialog}
        onClick={(e) => e.stopPropagation()}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <>
          <Title>{notifications[activeStep]?.title}</Title>
          <Description>{notifications[activeStep]?.mainDesc}</Description>
          <Description>{notifications[activeStep]?.secondaryDesc}</Description>
          {activeStep === 0 && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="submitted"
                  id="submitted"
                  required
                  checked={aszfAccepted}
                  onChange={(e) => {
                    setAszfAccepted(e.target.checked);
                  }}
                />
              }
              label={
                <p className={classes.data}>
                  Az alábbi &nbsp;
                  {baseDocuments?.map((el, idx) => {
                    return (
                      <span key={idx}>
                        <a
                          href="/"
                          className={classes.link}
                          onClick={(event) =>
                            {
                              event.preventDefault();
                              downloadBaseDocuments(el.id, el.name)
                            }
                          }
                        >
                          {el.name}
                        </a>
                          {idx !== baseDocuments.length -1 && ' és az '}
                      </span>
                    );
                  })}
                  &nbsp;dokumentumokban foglaltakat elfogadom.
                </p>
              }
            />
          )}
        </>
        {notifications?.length > 1 && (
          <MyStepper>
            {notifications?.map((notification, idx) => {
              return (
                <StepIndicator
                  key={idx}
                  style={{
                    background:
                      activeStep === idx
                        ? theme.palette.primary.backgroundPrimary
                        : "lightgrey",
                  }}
                  onClick={() => setActiveStep(idx)}
                />
              );
            })}
          </MyStepper>
        )}
        <ButtonsWrapper>
          {notifications?.length > 1 && (
            <Button
              disabled={activeStep === 0}
              className={classes.backBtn}
              style={{
                background:
                  activeStep !== 0
                    ? theme.palette.primary.backgroundPrimary
                    : "lightgrey",
              }}
              onClick={() => setActiveStep(activeStep - 1)}
            >
              Vissza
            </Button>
          )}
          {notifications[activeStep]?.skippable ? (
            <Button
              className={classes.btn}
              disabled={!aszfAccepted}
              onClick={() => {
                if (notifications[activeStep]?.skippable) {
                  activeStep === notifications?.length - 1
                    ? setOpen(false)
                    : setActiveStep(activeStep + 1);
                }
              }}
            >
              {activeStep === notifications?.length - 1
                ? notifications[activeStep]?.btnText
                : "Tovább"}
            </Button>
          ) : (
            <></>
          )}
        </ButtonsWrapper>
      </Dialog>
    </>
  );
};

export default ShopNotificationDialog;
