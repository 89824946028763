import { getApi } from "api/axios";
import { useState } from "react";
import { useCallback } from "react";

export const useNotificationAPI = () => {
  const [notifications, setNotifications] = useState();

  const fetchNotifications = useCallback(async () => {
    try {
      setNotifications(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/pop-up-notifications/active`);
      setNotifications(res?.data);
    } catch (e) {}
  }, []);

  return { notifications, fetchNotifications };
};
