import * as React from "react";
import styles from "./Blog.module.css";
import BlogCard from "../../components/blog/BlogCard/BlogCard";
import Center from "../../components/General/center/Center";
import Divider from "../../components/General/divider/Divider";
import { useBlogsLogic } from "./useBlogsLogic";
import { CircularProgress, Pagination } from "@mui/material";
import CustomNoRowsOverlay from "../../components/blog/customNoRowsOverlay/CustomNoRowsOverlay";
import { Helmet } from "react-helmet";
import { useLocation, useHistory } from "react-router-dom";

const Blog = () => {
  const { fetchBlogs, paginationModel, setPaginationModel } = useBlogsLogic();
  const history = useHistory();
  const { search: params } = useLocation();

  const handleChange = (event, value) => {
    history.push(`?page=${value}`);
  };

  const currentPage = new URLSearchParams(params).get("page");

  // Determine what page is viewed through query params
  React.useEffect(() => {
    if (
      currentPage ? parseInt(currentPage) : 1 !== parseInt(paginationModel.page)
    ) {
      setPaginationModel({
        ...paginationModel,
        page: currentPage ? parseInt(currentPage) : 1,
      });
    }
  }, [currentPage]);

  return (
    <main className={styles.container}>
      <Helmet>
        <title>Iskolaévkönyv - Blogok</title>
        <meta
          name="description"
          content={
            "Maradjon naprakész a blogunkon található érdekes tartalmakkal és a legfrissebb hírekkel."
          }
        />
      </Helmet>
      <Center element="section" sx={styles.custom_center}>
        <div className={styles.blog_header}>
          <h1>Blogok</h1>
        </div>
        <Divider />
        <div className={styles.outer_container}>
          <div className={styles.card_container}>
            {fetchBlogs.isLoading ? (
              <CircularProgress size={100} />
            ) : fetchBlogs.data?.data?._embedded?.blogDTOList ? (
              fetchBlogs.data?.data?._embedded?.blogDTOList?.map((item) => (
                <BlogCard
                  key={item.id}
                  title={item.title}
                  img={item.thumbnail}
                  date={item.createdDate}
                  url={item.slug}
                  language={item.language ?? "EN"}
                  buttonText={"Tovább olvasok"}
                />
              ))
            ) : (
              <div className={styles.no_blog}>
                <CustomNoRowsOverlay text={"Nincs elérhető blog"} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.pagination}>
          <Pagination
            hideNextButton={fetchBlogs.data?.data?.page?.totalPages < 2}
            count={fetchBlogs.data?.data?.page?.totalPages}
            page={paginationModel.page}
            onChange={handleChange}
          />
        </div>
      </Center>
    </main>
  );
};

export default Blog;
