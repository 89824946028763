import React from "react";

const Divider = ({ orientation, className }) => {
  return orientation === "vertical" ? (
    <div
      className={className}
      style={{
        border: "none",
        width: "1px",
        margin: "0",
        flexShrink: "0",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
      }}
    ></div>
  ) : (
    <div
      className={className}
      style={{
        border: "none",
        height: "1px",
        margin: "0",
        flexShrink: "0",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
      }}
    ></div>
  );
};

export default Divider;
