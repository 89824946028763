import React from "react";
import { CircularProgress, Typography, useTheme } from "@material-ui/core";

import { LoadingWrapper, useStyles } from "./styles";

const LoadIndicator = ({ color, height }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <LoadingWrapper height={height}>
      <Typography style={{ color: color }}>Egy pillanat ...</Typography>
      <CircularProgress
        size={70}
        className={classes.spinner}
        style={{ color: color }}
      />
    </LoadingWrapper>
  );
};

export default LoadIndicator;
