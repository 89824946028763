import { useEffect } from "react";
import { TextField } from "@material-ui/core";

import { useStyles } from "./styles";

const CountStep = (props) => {
  const classes = useStyles();
  useEffect(() => {
    props.setQuantity(1);
  }, []);
  return (
    <>
      <TextField
        className={classes.textField}
        style={{
          display: props.format === 'EMAIL' || props.cartLoading ? "none" : "flex",
        }}
        defaultValue={1}
        type="number"
        variant="outlined"
        InputProps={{
          className: classes.textField,
          inputProps: {
            max: 100,
            min: 1, // todo this should be configurable
          },
        }}
        onChange={(e) => {
          props.setQuantity(e.target.value);
          e.preventDefault();
        }}
      />
    </>
  );
};

export default CountStep;
