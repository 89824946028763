/** @format */
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import Products from './Products/Products';
import background from 'assets/images/test.jpg';
import defaultImage from 'assets/images/test.jpg';
import GroupCard from 'components/Group/GroupCard/GroupCard';
import {
  ProductsWrapper,
  HomeWrapper,
  IntroSection,
  BackgroundWrapper,
  BackgroundImage,
  TitleContainer,
  Title,
  GroupsWrapper,
  BreadcrumbWrapper
} from './styles';
import { Slide, useMediaQuery } from '@material-ui/core';
import LoadIndicator from 'components/LoadIndicator/LoadIndicator';
import ShopNotificationDialog from 'components/ShopNotificationDialog/ShopNotificationDialog';
import { Helmet } from 'react-helmet';
import { useGroupLogic } from './useGroupLogic';

const Group = (props) => {
  const mobile = useMediaQuery('(max-width: 480px)');
  const tablet = useMediaQuery('(min-width: 480px)');
  const desktop = useMediaQuery('(min-width: 992px)');
  const desktopXl = useMediaQuery('(min-width: 1200px)');
  const desktopXXl = useMediaQuery('(min-width: 1921px)');

  const {
    lastRef,
    infiniteProducts,
    getValidUntilFields,
    header,
    stickyBreadcrumb,
    location,
    shopId,
    cover,
    headerLoading,
    loading,
    groups,
    getQdakAccessKey,
    id
  } = useGroupLogic();

  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - Böngésző</title>
        <meta name='description' content='Böngéssz a képek között, hogy megtaláld a megfelelőt számodra.' />
      </Helmet>
      <BreadcrumbWrapper>
        <Breadcrumb
          header={header}
          sticky={stickyBreadcrumb}
          shopId={location.search === '' && shopId ? shopId : localStorage.getItem('shopId')}
        />
      </BreadcrumbWrapper>

      {headerLoading ? (
        <LoadIndicator height={900} />
      ) : (
        <HomeWrapper>
          <Slide direction='down' in={true} timeout={700}>
            <IntroSection>
              <BackgroundWrapper>
                <BackgroundImage src={cover || background} />
              </BackgroundWrapper>
              <TitleContainer>
                <Title>{header?.name}</Title>
              </TitleContainer>
            </IntroSection>
          </Slide>
          {header?.modifiers && getValidUntilFields(header?.modifiers)}
          {loading ? (
            <LoadIndicator />
          ) : (
            <>
              {groups && (
                <GroupsWrapper id='groups'>
                  {groups
                    ?.sort((a, b) => {
                      return a.name.localeCompare(b.name);
                    })
                    .map((group, idx) => {
                      return (
                        <GroupCard
                          groupId={group?.id}
                          groupCategory={group.category}
                          key={idx}
                          groupName={group.name}
                          imgSrc={group.url ? group.url : defaultImage}
                          belongsToId={header?.id}
                          qdakAccessKey={getQdakAccessKey}
                        />
                      );
                    })}
                </GroupsWrapper>
              )}

              {header?.belongsTo !== null && (
                <ProductsWrapper>
                  {infiniteProducts && (mobile || tablet || desktop || desktopXl || desktopXXl) && (
                    <Products
                      group={header}
                      qdakAccessKey={getQdakAccessKey()}
                      products={infiniteProducts}
                      lastRef={lastRef}
                      id={id}
                      shopId={location.search === '' && shopId ? shopId : localStorage.getItem('shopId')}
                      path={background}
                    />
                  )}
                </ProductsWrapper>
              )}
            </>
          )}
        </HomeWrapper>
      )}
      <ShopNotificationDialog open={props.showValidUntil} setOpen={props.setShowValidUntil} />
    </main>
  );
};

export default Group;
