import { toast } from 'react-toastify';
import siteconfig from "siteconfig.json"

const successStyle = {
    backgroundColor: siteconfig.themes.primary.success
}
const errorStyle = {
    backgroundColor: siteconfig.themes.primary.error
}
const infoStyle = {
    backgroundColor: siteconfig.themes.primary.info
}

const toastConfig = {
    position: "bottom-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

const progressConfig = {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    closeButton: false,
    draggable: false,
    progress: undefined,
}

const progressToast = (message) => {
    toast.info(message, progressConfig)
}
const successToast = (message) => {
    toast.success(message, { ...toastConfig, style: successStyle })
}
const errorToast = (message) => {
    toast.error(message, { ...toastConfig, style: errorStyle })
}

const infoToast = (message) => {
    toast.info(message, { ...toastConfig, style: infoStyle })
}

export { successToast, errorToast, infoToast, progressToast }