import { getApi } from "api/axios";
import { useCallback, useState } from "react";

export const usePaymentAPI = () => {
  const [paymentDetails, setPaymentDetails] = useState();
  const [paymentOk, setPaymentOk] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(true);

  const [externalOk, setExternalOk] = useState(false);
  const [externalId, setExternalId] = useState(null);
  const [externalIdLoading, setExternalIdLoading] = useState(false);
  const [externalIdError, setExternalIdError] = useState(null);

  const getExternalCheckoutId = async (checkoutId, body, shopId) => {
    try {
      setExternalOk(false);
      setExternalIdLoading(true);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PAYMENT_URL })
      ).post(`/payments/${checkoutId}/ack`, body);
      setExternalIdLoading(false);
      setExternalId(res.data.externalId); // TODO do this according to backend naming
      setPaymentDetails(res.data);
      if (res.data.status === "CONFIRMED" || res.data.status === "PAID") {
        localStorage.removeItem("cartId");
        setExternalOk(true);
      } else {
        setExternalOk(false);
      }
    } catch (e) {
      setExternalIdLoading(false);
      setExternalIdError(e);
      setExternalOk(false);
    }
  };

  const postPaymentDetails = useCallback(async ({ id, body }) => {
    try {
      setPaymentOk(false);
      setPaymentLoading(true);
      setPaymentDetails(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PAYMENT_URL })
      ).post(
        `/payments/${id}/confirmations`,

        body,

        {
          "Content-Type": "application/json",
        }
      );
      setPaymentLoading(false);
      if (res.data.status === "SUCCESS") {
        setPaymentOk(true);
      } else {
        setPaymentOk(false);
      }
      setPaymentDetails(res.data);
    } catch (e) {
      setPaymentOk(false);
      setPaymentLoading(false);
    }
  }, []);
  return {
    postPaymentDetails,
    paymentDetails,
    paymentOk,
    paymentLoading,
    externalId,
    externalIdLoading,
    externalIdError,
    getExternalCheckoutId,
    externalOk,
  };
};
