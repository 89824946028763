import { useCallback, useState } from "react";

export const useGetAboutMeAPI = () => {
  const [shortDescription, setShortDescription] = useState();

  const fetchShortDescription = useCallback(async () => {
    try {
      setShortDescription(null);
      //const res = await (await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })).get(
      //    `/aboutMe`
      //)
      const res = `<div class="wrapper"><ol><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum eu turpis ac porta. Integer.</li><li><em><a rel="noopener noreferrer" href="https://www.datatronic.hu" target="_blank" >Pellentesque feugiat dui eros, ac molestie mauris vehicula quis. Morbi interdum velit nec tortor dapibus.</a></em></li><li><strong>Fusce egestas posuere semper. Aenean pretium eros justo, nec laoreet est iaculis eget. Aliquam scelerisque.</strong></li></ol><ul><li>Duis vitae efficitur lacus.</li><li>Nulla vulputate velit vel eros imperdiet auctor.</li><li>Morbi eu posuere arcu.</li></ul><p><br></p></div>`;

      setShortDescription(res);
    } catch (e) {}
  }, []);

  const [longDescription, setLongDescription] = useState();

  const fetchLongDescription = useCallback(async () => {
    try {
      setLongDescription(null);
      //const res = await (await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })).get(
      //    `/aboutMe`
      //)
      const res = `<div class="wrapper"><ul><li>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris blandit, nibh in scelerisque fringilla, massa arcu rhoncus odio, sed consequat dolor turpis mollis ante. Quisque elementum ullamcorper risus ac auctor. Praesent consequat, justo eget porta hendrerit.</li><p><br></p><li><em>Vestibulum et malesuada sapien. In id aliquam purus, convallis fringilla ligula. Donec quis suscipit magna, vel egestas nulla. Mauris quis mauris nec ante dignissim laoreet gravida in risus. Nulla facilisi. Etiam at malesuada lacus. Praesent tincidunt ante et risus blandit.</em></li><p><br></p><li><strong>Donec ultricies consectetur nulla. Curabitur pharetra ac felis ut porta. Aliquam bibendum nibh non nisi tempus, at pharetra sapien maximus. Nulla sollicitudin massa tincidunt nunc tempus mattis. Nullam bibendum, dui non ultricies aliquam, metus ante dignissim arcu, quis bibendum ante.</strong></li><p><br></p></ul></div>`;

      setLongDescription(res);
    } catch (e) {}
  }, []);

  return {
    shortDescription,
    fetchShortDescription,
    longDescription,
    fetchLongDescription,
  };
};
