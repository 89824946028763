import * as yup from "yup";

const BillingSchema = yup.object({
  firstName: yup
    .string("Adja meg keresztnevét")
    .matches(
      /^([A-ZÁÉÚŐÓÜÖÍ]([A-ZÁÉÚŐÓÜÖÍa-záéúőóüöí -.]+\s?))$/,
      "Helytelen formátum"
    )
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  lastName: yup
    .string("Adja meg vezetéknevét")
    .matches(
      /^([A-ZÁÉÚŐÓÜÖÍ]([A-ZÁÉÚŐÓÜÖÍa-záéúőóüöí -.]+\s?))$/,
      "Helytelen formátum / Csak betűket tartalmazhat"
    )
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  email: yup
    .string("Adja meg email címét")
    .email("Kérem adjon meg egy valós email címet")
    .required("Szükséges mező"),
  emailConfirm: yup
    .string("Erősítse meg email címét")
    .email("Kérem adjon meg egy valós email címet")
    .required("Szükséges mező")
    .when("email", (email) => {
      if (email) {
        return yup
          .string("Erősítse meg email címét")
          .email("Kérem adjon meg egy valós email címet")
          .required("Szükséges mező")
          .test(
            "do-the-passwords-match",
            "A két email cím nem egyezik!",
            function (emailConfirm) {
              return email === emailConfirm;
            }
          );
      }
    }),
  phone: yup
    .string("Adja meg a telefonszámát")
    .matches(/^[0-9]{8,9}$/, "Helyes formátum: 301234567")
    .required("Szükséges mező"),
  companyName: yup
    .string("Adja meg a cég nevét")
    .ensure()
    .when("isCompany", {
      is: true,
      then: yup
        .string()
        .max(40, "Nem lehet hosszabb 40 karakternél")
        .required("Szükséges mező"),
    }),
  VATNumber: yup
    .string("Adja meg adószámát")
    .ensure()
    .when("isCompany", {
      is: true,
      then: yup
        .string()
        .max(40, "Nem lehet hosszabb 40 karakternél")
        .required("Szükséges mező")
        .matches(/^\d+$/, "Csak számokat tartalmazhat"),
    }),
  street: yup
    .string("Adja meg a közterület nevét")
    .min(1, "Adja meg a közterület nevét")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  city: yup
    .string("Adja meg városát")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  zip: yup
    .number("Adja meg városának irányítószámát")
    .min(999, "Irányítószáma egy 4 jegyű szám")
    .max(9999, "Irányítószáma egy 4 jegyű szám")
    .required("Szükséges mező"),
  country: yup
    .string("Adja meg országát")
    .min(4, "Nem lehet rövidebb mint 4 karakter")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  isCompany: yup.boolean(),
});

const AddressSchema = yup.object({
  address: yup
    .string("Adja meg a közterület nevét")
    .min(1, "Adja meg a közterület nevét")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  streetNo: yup
    .string("Adja meg házszámot")
    .max(20, "Nem lehet hosszabb 20 karakternél")
    .required("Szükséges mező"),
  city: yup
    .string("Adja meg városát")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  zip: yup
    .number("Adja meg városának irányítószámát")
    .min(999, "Irányítószáma egy 4 jegyű szám")
    .max(9999, "Irányítószáma egy 4 jegyű szám")
    .required("Szükséges mező"),
  country: yup
    .string("Adja meg országát vagy országának jelzését")
    .min(1, "Adja meg országát vagy országának jelzését")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),

  saveAddress: yup.boolean(),
});
const CompanyBillingSchema = yup.object({
  companyName: yup
    .string("Adja meg a cég nevét")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  VATNumber: yup
    .string("Adja meg adószámát")
    .matches(/^[0-9]+$/, "Csak számokat tartalmazhat")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  address: yup
    .string("Adja meg a közterület nevét")
    .min(1, "Adja meg a közterület nevét")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  streetNo: yup
    .string("Adja meg házszámot")
    .max(20, "Nem lehet hosszabb 20 karakternél")
    .required("Szükséges mező"),
  city: yup
    .string("Adja meg városát")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  zip: yup
    .number("Adja meg városának irányítószámát")
    .min(999, "Irányítószáma egy 4 jegyű szám")
    .max(9999, "Irányítószáma egy 4 jegyű szám")
    .required("Szükséges mező"),
  country: yup
    .string("Adja meg országát vagy országának jelzését")
    .min(1, "Adja meg országát vagy országának jelzését")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
});
const PersonalBillingSchema = yup.object({
  firstName: yup
    .string("Adja meg keresztnevét")
    .matches(
      /^([A-ZÁÉÚŐÓÜÖÍ]([A-ZÁÉÚŐÓÜÖÍa-záéúőóüöí -.]+\s?))$/,
      "Helytelen formátum / Csak betűket tartalmazhat"
    )
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  lastName: yup
    .string("Adja meg vezetéknevét")
    .matches(
      /^([A-ZÁÉÚŐÓÜÖÍ]([A-ZÁÉÚŐÓÜÖÍa-záéúőóüöí -.]+\s?))$/,
      "Helytelen formátum / Csak betűket tartalmazhat"
    )
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  address: yup
    .string("Adja meg a közterület nevét")
    .min(1, "Adja meg a közterület nevét")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  streetNo: yup
    .string("Adja meg házszámot")
    .max(20, "Nem lehet hosszabb 20 karakternél")
    .required("Szükséges mező"),
  city: yup
    .string("Adja meg városát")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  zip: yup
    .number("Adja meg városának irányítószámát")
    .min(999, "Irányítószáma egy 4 jegyű szám")
    .max(9999, "Irányítószáma egy 4 jegyű szám")
    .required("Szükséges mező"),
  country: yup
    .string("Adja meg országát vagy országának jelzését")
    .min(1, "Adja meg országát vagy országának jelzését")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
});

const DescSchema = yup.object({
  description: yup
    .string("Adja meg az osztályfőnök nevét és az osztályt")
    .required("Szükséges mező"),
});

const DePaSchema = yup.object({
  deliveryMethod: yup
    .string("Adja meg a szállítás módját")
    .required("Szükséges mező"),
  paymentMethod: yup
    .string("Adja meg a fizetés módját")
    .required("Szükséges mező"),
});

export {
  BillingSchema,
  AddressSchema,
  CompanyBillingSchema,
  PersonalBillingSchema,
  DescSchema,
  DePaSchema,
};
