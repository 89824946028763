import * as React from "react";
import styles from "./BlogSidebar.module.css";
import { Link } from "react-router-dom";
import Divider from "../../General/divider/Divider";
import { Skeleton } from "@mui/material";
import { useBlogsLogic } from "pages/Blog/useBlogsLogic";

const BlogSidebar = ({ id }) => {
  const { fetchBlogs } = useBlogsLogic();

  const getRandomBlogs = () => {
    return fetchBlogs.data?.data?._embedded?.blogDTOList
      .filter((blog) => blog.id !== id)
      .slice(0, 2);
  };

  return (
    <div className={styles.sidebar_container}>
      <h3 className={styles.sidebar_top}>További blogok</h3>
      <Divider />
      {fetchBlogs.data?.data?._embedded?.blogDTOList ? (
        <div className={styles.link_container}>
          {getRandomBlogs().length > 0 ? (
            getRandomBlogs()?.map((item) => (
              <Link
                key={item.id}
                to={`/blog/${item.slug}`}
                className={styles.text_container}
              >
                {item.title}
              </Link>
            ))
          ) : (
            <div>Nincs elérhető blog</div>
          )}
        </div>
      ) : (
        <div>
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"120px"}
            style={{ margin: "1rem 0" }}
          />
        </div>
      )}
    </div>
  );
};

export default BlogSidebar;
