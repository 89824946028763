import { getApi } from "../axios";

export default {
  async fetchBlogs({ paginationModel }) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
        auth: true,
      })
    ).get(
      `blogs/?page=${paginationModel.page}&size=${paginationModel.pageSize}&sort=id,desc`
    );
  },

  async fetchBlog(urlSlug) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
        auth: true,
      })
    ).get(`blogs/${urlSlug}`);
  },
};
