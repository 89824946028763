import * as yup from 'yup';

const ContactSchema = yup.object({
  name: yup
    .string('Adja meg name')
    .max(50)
    .required('Szükséges mező'),
  email: yup
    .string('Adja meg email címét')
    .email('Kérem adjon meg egy valós email címet')
    .required('Szükséges mező'),
  message: yup
    .string('Adja meg üzenetének szövegét')
    .max(300, "Az üzenet legfeljebb 300 karakter hosszú lehet")
    .required('Szükséges mező'),
});

export { ContactSchema }