import { createSlice } from "@reduxjs/toolkit";

export const accessKeySlice = createSlice({
  name: "accessKeySlice",
  initialState: {
    accessKeys: {},
  },
  reducers: {
    setAccessKey: (state, action) => {
      let newAccessKeys = {};
      for (const key in state.accessKeys) {
        newAccessKeys[key] = state.accessKeys[key];
      }
      newAccessKeys[action.payload.id] = action.payload.accessKey;
      state.accessKeys = newAccessKeys;
      if (localStorage.getItem(action.payload.id) === null) {
        localStorage.setItem(action.payload.id, action.payload.accessKey);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAccessKey } = accessKeySlice.actions;

export default accessKeySlice.reducer;
