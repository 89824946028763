import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.primary.textDark,
    fontWeight: "bold",
    textTransform: "uppercase",
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    marginTop: "20px",
  },

  input: {
    "& .MuiFormLabel-root, & .MuiInputBase-root, & .MuiTypography-root": {
      fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    },
  },
  button: {
    background: theme.palette.primary.backgroundSecondary,
    color: theme.palette.primary.textSecondary,
    '&:hover': {
      background: theme.palette.primary.backgroundPrimary
    }
  },
}));
