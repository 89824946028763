import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
import SIZES from "constants/sizes";
import blende_img from '../../assets/images/svg/blende_grey.svg';

export const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 50;
  width: 100%;
  margin: 0;
  padding: 0;
  background-image: url(${blende_img}), url(${blende_img});
  background-repeat: no-repeat, no-repeat;
  background-position-X: -25%, 130%;
  background-position-Y: -200px, 250px;
  background-size: 40%, 50%;
`;

export const CardTitle = styled.h1`
  font-family: ${({ theme }) => theme.typography.main}, Sans-serif, Arial;
  color: ${({ theme }) => theme.palette.primary.textPrimary};
`;

export const ContactWrapper = styled.div`
  display: flex;
  font-family: raleway;
  width: 100%;
  max-width: 1000px;
  margin: 4rem 0;
`;

export const ContactWrapperBack = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.palette.primary.backgroundSecondary};
  opacity: 35%;
  position: absolute;
  transform: rotate(-4deg);
  z-index: -1;
  left: 1rem;
  display: none;
  @media (min-width: ${SIZES.MD}px) {
    display: block;
  }
`;
export const ContactWrapperBack2 = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.palette.primary.backgroundSecondary};
  opacity: 20%;
  position: absolute;
  transform: rotate(-8deg);
  z-index: -1;
  left: 1rem;
  display: none;
  @media (min-width: ${SIZES.MD}px) {
    display: block;
  }
`;
export const UpperWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AddressesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 2rem;
  margin: 2.5rem 0 0;

  @media (min-width: ${SIZES.SM}px) {
  }
`;

export const PortraitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 0 0;

  @media (min-width: ${SIZES.SM}px) {
  }
`;

export const PortraitImage = styled.img`
  width: 30vw;
  height: 30vw;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.22), 0 6px 20px 0 rgba(0, 0, 0, 0.22);
  border: 6px solid ${(props) => props.theme.palette.primary.backgroundPrimary};
  background-color: ${(props) => props.theme.palette.primary.backgroundContent};
`;

export const Name = styled.h1`
  font-size: 1, 5rem;
  font-weight: 600;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.textSecondary};
`;

export const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100%",
    overflow: "visible",
    position: "relative",
  },
  forms: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",

    borderRadius: "0",
  },
  formpadding: {
    padding: "1rem",
    background: theme.palette.primary.backgroundSecondary,
  },
  contacts: {
    backgroundColor: theme.palette.primary.backgroundSecondary,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",

    borderRadius: "0",
    position: "relative",
    [theme.breakpoints.up("md")]: {},
  },
  iconButton: {
    padding: "1rem",
    fontSize: "1rem",
    borderRadius: "11px",
    color: theme.palette.primary.textSecondary,
    backgroundColor: theme.palette.primary.backgroundSecondary,
    transition: "250ms ease-in",
    "&:hover, &:focus": {
      color: theme.palette.primary.textPrimary,
      backgroundColor: theme.palette.primary.backgroundPrimary,
    },
    float: "right",
  },
  tableCell: {
    borderBottom: "none",
    fontSize: "0.9rem",
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    width: "100%",
    color: theme.palette.primary.textSecondary,
  },
  nametableCell: {
    fontSize: "1rem",
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    textTransform: "uppercase",
    color: theme.palette.primary.textSecondary,
    fontWeight: 500,
  },

  input: {
    color: `${theme.palette.primary.textPrimary}`,
    width: "90%",
    margin: "0 auto",
    "& .MuiFormLabel-root": {
      color: `${theme.palette.primary.textPrimary}`,
      fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    },
    "& .MuiInputBase-root": {
      color: `${theme.palette.primary.textPrimary}`,
      fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottomColor: theme.palette.primary.textPrimary,
    },
  },
  label: {
    width: "90%",
    margin: "1.5rem auto 0.5rem",
    color: `${theme.palette.primary.textPrimary}`,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
  },

  submitBtn: {
    color: 'black',
    margin: "0.4rem 0",
    width: "90%",
  },

  textarea: {
    width: "90%",
    margin: "0 auto",
    color: `${theme.palette.primary.textPrimary}`,
    outline: "1px white",
    background: "transparent",

    height: "16rem !important",

    resize: "none",
    border: `1px solid ${theme.palette.primary.textPrimary}`,
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    transition: "border 130ms",

    "&:hover": {
      borderBottom: "2px solid black",
    },
  },

  icon: {
    borderBottom: "none",
    fontSize: "1.2rem",
    color: theme.palette.primary.textSecondary,
  },
  breadcrumb: {
    margin: "1rem",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    outline: "none !important",
  },
  arrow: {
    marginLeft: "0.5rem",
  },
}));
