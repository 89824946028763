import styled from "styled-components";
import { makeStyles } from "@material-ui/core";

export const IntroSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (min-width: 992px) {
    padding: 2rem 0;
  }
`;

export const IntroSectionDiv = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const PortraitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  @media (min-width: 480px) {
    flex-direction: row;
  }
`;

export const PortraitInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.primary.backgroundContent};
  border-radius: 50%;
  overflow: hidden;
  width: 120px;
  height: 120px;
 
`;

export const PortraitImage = styled.img`
  width: 60vw;
  max-width: 100px;
  margin: 0 auto;

`;

export const BlendeImageWrapper = styled.div`
  display: hidden;
  position: absolute;
  @media (min-width: 1250px) {
    display: block;
    height: 980px;
    width: 980px;
    bottom: 0;
    right: 0rem;
    position: absolute;
  }
`;
export const BlendeImageWrapper2 = styled.div`
  display: hidden;
  position: absolute;
  @media (min-width: 1250px) {
    display: block;
    height: 50%;
    width: 250px;
    top: 0;
    left: 0;
    margin-bottom: 1.5rem;
  }
`;

// export const BlendeImageWrapper = styled.div`
//   height: 190%;
//   width: 700px;
//   position: absolute;
//   right: 0rem;
//   z-index: 1;
//   margin-bottom: 1.5rem;
// `;

export const Name = styled.div`
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.textSecondary};
  margin: 1rem;
  text-transform: uppercase;
  line-height: 90%;
  margin-top: 1rem;
`;

export const Description = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.palette.primary.textSecondary};
  max-width: 900px;
  line-height: 150%;
  padding-bottom: 1.5rem;
  text-align: center;
  z-index: 2;
  margin: 2rem;
  @media (min-width: 992px) {
    margin: 5rem 0 0 0;
  }
`;

export const HeroSection = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0 2rem 0;
  flex-direction: column;
  @media (min-width: 768px) {
    margin: 4rem 0 4rem 0;
    gap: 3rem;
    flex-direction: row;
  }
  @media (min-width: 992px) {
    margin: 1rem 0 1rem 0;
  }
  @media (min-width: 1200px) {
    gap: 8rem;
  }
`;

export const ProcessIcons = styled.div`
  display: flex;
  align-self: start;
  padding: 0.9rem 0.9rem;
  border-radius: 2.5rem;
  background-color: white;
  box-shadow: 0 0 12px -5px var(--p-900);
`;

export const ProcessDescription = styled.div`
  text-align: left;

  h3 {
    margin: unset;
    margin-top: 0rem;
  }

  p {
    margin: 0.5em auto 0;
  }
`;

export const ProcessContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 3.5rem 1rem;
  gap: 3.5rem 1rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  @media (min-width: 992px) {
    grid-gap: 3.5rem 1.5rem;
    gap: 3.5rem 1.5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;



export const StartButton = styled.button`
  background: white;
  color: black;
  border: none;
  width: 100%;
  height: 40px;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border-Radius: 20px;
  font-size: 1rem;
  align-self: center;
  transition: background 300ms;
  font-family:${({ theme }) => theme.typography.main}, Sans-serif, Arial;
  @media (min-width: 480px) {
    margin-top: 0.4rem;
  }
  &:hover {
    opacity: 80%;
  },`;

export const useStyles = makeStyles((theme) => ({
  introSection: {
    backgroundColor: theme.palette.primary.backgroundSecondary,
  },
  introText: {
    color: theme.palette.primary.textSecondary,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    "& h1": {
      lineHeight: "2.5rem",
      fontSize: "28px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "35px",
        lineHeight: "3rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "45px",
        lineHeight: "4rem",
      },
    },
    "& p": {
      fontSize: "18px",
      lineHeight: "1.75rem",
    },
  },
  introTitle: {
    color: theme.palette.primary.textSecondary,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: "28px",
   
  },
  secondIntroTitle: {
    fontSize: '35px !important',
    textAlign: 'center !important',
    [theme.breakpoints.up("sm")]: {
      textAlign: 'left !important'
    },
  },
  underline: {
    textDecorationColor: theme.palette.primary.dark,
    textUnderlineOffset: "5px",
  },
  processPlusLinkWrapper:{
    display: 'flex',
    flexDirection: 'column'
  },
 
}));
