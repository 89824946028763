import { useMediaQuery } from '@mui/material';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
export const useProductsLogic = (products, accessKey, shopId) => {
  const baseUrl = window._env_.REACT_APP_QDAK_PRODUCT_URL;
  const mobile = useMediaQuery('(max-width: 480px)');
  const tablet = useMediaQuery('(min-width: 480px)');
  const desktop = useMediaQuery('(min-width: 992px)');
  const desktopXl = useMediaQuery('(min-width: 1200px)');
  const desktopXXl = useMediaQuery('(min-width: 1921px)');
  const columnRef = useRef([]);
  const pageRefs = useRef([]);
  const [galleryEndVisible, setGalleryEndVisible] = useState(false);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState([{ items: [], centerEl: 0 }]);
  const [columns, setColumns] = useState(
    (desktop || desktopXl || tablet || mobile) &&
      Array.from(
        { length: desktopXXl ? 5 : desktopXl ? 4 : desktop ? 3 : tablet ? 2 : mobile && !tablet ? 1 : 1 },
        () => []
      )
  );

  const galleryEndRef = useRef();

  const [currentPage, setCurrentPage] = useState(0);

  const handleBack = () => {
    if (pageRefs?.current[currentPage - 1]) {
      pageRefs?.current[currentPage - 1]?.scrollIntoView(false);
    } else {
      pageRefs?.current[0]?.scrollIntoView(false);
    }
  };
  const handleForward = () => {
    if (pageRefs?.current[currentPage + 1]) {
      pageRefs?.current[currentPage + 1]?.scrollIntoView(false);
    } else {
      galleryEndRef?.current?.scrollIntoView(false);
    }
  };
  //For checking current positions in gallery

  const paginationHandler = () => {
    if (pageRefs?.current) {
      pageRefs.current?.forEach((el, idx) => {
        const rect = el.getBoundingClientRect();
        const isPartiallyVisible =
          rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
          rect.bottom > 0 &&
          rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
          rect.right > 0;

        if (isPartiallyVisible) {
          setCurrentPage(idx);
        }
      });
    }

    if (galleryEndRef?.current) {
      const rect = galleryEndRef?.current.getBoundingClientRect();
      if (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      ) {
        setGalleryEndVisible(true);
      } else {
        setGalleryEndVisible(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', paginationHandler);

    return () => {
      document.removeEventListener('scroll', paginationHandler);
    };
  }, []);

  // Function to distribute items into columns
  const distributeItems = (items) => {
    const newColumns =
      columns ||
      Array.from(
        { length: desktopXXl ? 5 : desktopXl ? 4 : desktop ? 3 : tablet ? 2 : mobile && !tablet ? 1 : 1 },
        () => []
      );
    items.forEach((item) => {
      !newColumns?.flatMap((el) => el)?.find((el) => el?.id == item?.id) && newColumns[findShortestColumn()].push(item);
    });
    setColumns(newColumns);
  };
  // Frontend page generation
  const pageGenerator = (items) => {
    let currentpagedata = Array.from({ length: Math.ceil(items?.length / 20) }, () => ({ items: [], centerEl: 0 }));

    items?.forEach((item, idx) => {
      const pageIndex = Math.ceil((idx + 1) / 20) - 1;
      currentpagedata[pageIndex]?.items.push(item);
    });
    setPageData(
      currentpagedata?.map((page, idx) => ({
        ...page,
        centerEl: page?.items[Math.round((page?.items?.length - 1) / 2)]?.id,
        pageNumber: idx
      }))
    );
  };

  const findShortestColumn = () => {
    let minIndex = 0;
    let minHeight = columnRef.current[0]?.clientHeight || 0;
    columnRef.current.forEach((colRef, index) => {
      if (colRef?.clientHeight < minHeight) {
        minHeight = colRef?.clientHeight;
        minIndex = index;
      }
    });
    return minIndex;
  };
  // Main masonry layout function
  const LayoutGenerator = async () => {
    if (
      products?.pages?.flatMap((page) => page?.data?._embedded?.productDTOList) &&
      (tablet || desktop || desktopXl || desktopXXl || mobile)
    ) {
      const newColumns =
        columns ||
        Array.from(
          { length: desktopXXl ? 5 : desktopXl ? 4 : desktop ? 3 : tablet ? 2 : mobile && !tablet ? 1 : 1 },
          () => []
        );
      pageGenerator(products?.pages?.flatMap((page) => page?.data?._embedded?.productDTOList));
      await products?.pages
        ?.flatMap((page) => page?.data?._embedded?.productDTOList)
        ?.forEach(
          (item) =>
            !newColumns?.flatMap((el) => el)?.find((el) => el?.id == item?.id) &&
            distributeItems(
              products?.pages?.flatMap((page) =>
                page?.data?._embedded?.productDTOList?.map((product) => ({
                  ...product,
                  //NEEDED for thumbnail displaying in cart
                  loaded: false,
                  alt: `${product.id}_preview`,
                  src:
                    accessKey === null
                      ? `${baseUrl}/shops/${shopId}/products/${product.id}/thumbnail`
                      : `${baseUrl}/shops/${shopId}/products/${product.id}/thumbnail?accessKey=${accessKey}`
                }))
              )
            )
        );
    }
  };
  useLayoutEffect(() => {
    LayoutGenerator();
  }, [products, tablet, desktop, desktopXl, mobile, desktopXXl]);

  return {
    columns,
    columnRef,
    pageData,
    pageRefs,
    handleBack,
    handleForward,
    currentPage,
    galleryEndRef,
    error,
    setError,
    galleryEndVisible
  };
};
