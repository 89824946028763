import React from "react";
import styles from "./LabelledIconWidget.module.css";

const LabelledIconWidget = React.memo(({ icon, title, children }) => {
  return (
    <div className={styles.labelled_widget}>
      <div>{icon}</div>
      <div>
        <h3>{title}</h3>
        <p>{children}</p>
      </div>
    </div>
  );
});

export default LabelledIconWidget;
