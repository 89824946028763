import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  '& .MuiFormLabel-root, & .MuiInputBase-root, & .MuiAutocomplete-popper': {
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
  },
  autoComplete: {
    '& .MuiFormLabel-root, & .MuiInputBase-root, & .MuiAutocomplete-popper': {
      fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
    }
  },

  // zip: {
  //   width: "100%",
  //   "& .MuiFormLabel-root, & .MuiInputBase-root": {
  //     fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  //   },
  // },

  // city: {
  //   width: "100%",
  //   margin: '2rem 0',
  //   [theme.breakpoints.up("md")]: {
  //     width: "96%",
  //     marginLeft: "0.7rem",
  //     marginTop: "0.23rem",
  //   },
  // },

  container: {
    width: '100%'
  }
}));
