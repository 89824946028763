import { getApi } from "api/axios";
import { useCallback, useState } from "react";

import { convertBlobToBase64 } from "utils/Converters";

export const useGroupAPI = () => {
  const [groups, setGroups] = useState();

  const fetchGroups = useCallback(async () => {
    try {
      setGroups(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/groups`);
      setGroups(res.data);
    } catch (e) {
    }
  }, []);

  const [header, setHeader] = useState();

  const fetchHeader = useCallback(async ({ id }) => {
    try {
      setHeader(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/groups/${id}`);
      setHeader(res.data);
    } catch (e) {
    }
  }, []);

  const [cover, setCover] = useState();

  const fetchCover = useCallback(async ({ shopId, id, accessKey }) => {
    try {
      setCover(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/shops/${shopId}/groups/${id}/cover`, {
        responseType: "blob",
      });
      let image = await convertBlobToBase64(await res.data);
      setCover(image);
    } catch (e) {
    }
  }, []);

  const [products, setProducts] = useState();

  const fetchProducts = useCallback(async ({ id }) => {
    try {
      setProducts(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/groups/${id}/products`);
      setProducts(res.data);
    } catch (e) {
    }
  }, []);

  const [innerGroups, setInnerGroups] = useState();

  const fetchInnerGroups = useCallback(async ({ id }) => {
    try {
      setInnerGroups(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/groups/${id}/groups`);
      setInnerGroups(res.data);
    } catch (e) {
    }
  }, []);

  const [group, setGroup] = useState();

  const putGroup = useCallback(async ({ id, body, inheritHeader }) => {
    try {
      setGroup(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).put(`/groups?inheritHeader=${inheritHeader}`, body);
      setGroup(res.data);
    } catch (e) {
    }
  }, []);

  const postGroup = useCallback(async ({ id, body, inheritHeader }) => {
    try {
      setGroup(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).post(`/groups`, body);
      setGroup(res.data);
    } catch (e) {
    }
  }, []);

  const deleteGroup = useCallback(async ({ id }) => {
    try {
      setGroup(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).delete(`/groups/${id}`);
      setGroup(res.data);
    } catch (e) {
    }
  }, []);

  return {
    fetchGroups,
    groups,
    fetchHeader,
    header,
    fetchCover,
    cover,
    fetchProducts,
    products,
    fetchInnerGroups,
    innerGroups,
    postGroup,
    putGroup,
    group,
    deleteGroup,
  };
};
