import { useState, useCallback } from "react";
import { getApi } from "api/axios";

export const UseBreadcrumbData = () => {
  const [header, setHeader] = useState();
  const [parentHeader, setParentHeader] = useState();

  const fetchHeader = useCallback(async ({ shopId, id, accessKey }) => {
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/shops/${shopId}/groups/${id}`, {
        params: {
          accessKey,
        },
      });

      setHeader(res.data);
    } catch (e) {}
  }, []);
  const fetchParentHeader = useCallback(async ({ shopId, id, accessKey }) => {
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/shops/${shopId}/groups/${id}`, {
        params: {
          accessKey,
        },
      });

      setParentHeader(res.data);
    } catch (e) {}
  }, []);

  return { fetchHeader, header, fetchParentHeader, parentHeader };
};
