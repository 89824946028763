import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.backgroundContent,
    alignItems: 'center',
    marginBottom: '2rem'
  }
}));

export const OuterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ColumnWrapper = styled.div`
  width: 70%;
  height: max-content;
  display: flex;
  flex-direction: row;
  position: relative;
  column-gap: 0.7rem;
  overflow: hidden;
`;
export const GalleryStepper = styled.div`
  border-radius: 1rem;
  width: 200px;
  height: 60px;
  background: white;
  box-shadow: 0 0 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const GalleryStepperWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 2rem;
  left: 0;
  transition: 0.3s all;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 100%;
  column-gap: 0.5rem;
  overflow: hidden;
  row-gap: 0.4rem;
`;
